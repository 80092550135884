import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NgcCookieConsentService,
  NgcInitializingEvent,
  NgcInitializationErrorEvent,
  NgcStatusChangeEvent,
  NgcNoCookieLawEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { IconRegistryService } from './shared/helper-services/icon-registry.service';
import { ToastService } from './shared/helper-services/toast.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'OsavusAI';
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  toastMessage: string = '';
  toastType: string = '';
  showToast: boolean = false;
  
  constructor(
    // private _cookieService: NgcCookieConsentService,
    private _iconRegistryService: IconRegistryService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this._iconRegistryService.registerSvgIcons();

    this.toastService.message$.subscribe(message => {
      this.toastMessage = message;
    });

    this.toastService.type$.subscribe(type => {
      this.toastType = type;
    });

    this.toastService.isToasting$.subscribe(isToasting => {
      this.showToast = isToasting;
    });

    // TODO: connect data with API
    // if (!this._cookieService.hasAnswered()) {
    //   this._addCookieBackdrop();
    // }

    // this.popupOpenSubscription = this._cookieService.popupOpen$.subscribe(
    //   () => {
    //     this._addCookieBackdrop();
    //   },
    // );

    // this.popupCloseSubscription = this._cookieService.popupClose$.subscribe(
    //   () => {
    //     this._removeCookieBackdrop();
    //   },
    // );

    // this.initializingSubscription = this._cookieService.initializing$.subscribe(
    //   (event: NgcInitializingEvent) => {
    //     console.log(`initializing: ${JSON.stringify(event)}`);
    //   },
    // );

    // this.initializedSubscription = this._cookieService.initialized$.subscribe(
    //   (event) => {
    //     console.log(`initialized: ${JSON.stringify(event)}`);
    //   },
    // );

    // this.initializationErrorSubscription =
    //   this._cookieService.initializationError$.subscribe(
    //     (event: NgcInitializationErrorEvent) => {
    //       console.log(
    //         `initializationError: ${JSON.stringify(event.error?.message)}`,
    //       );
    //     },
    //   );

    // this.statusChangeSubscription = this._cookieService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {},
    // );

    // this.revokeChoiceSubscription = this._cookieService.revokeChoice$.subscribe(
    //   () => {},
    // );

    // this.noCookieLawSubscription = this._cookieService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {},
    // );
  }

  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  private _addCookieBackdrop(): void {
    const div = document.createElement('div');
    div.id = 'cookie-backdrop';
    div.classList.add('cookie-policy-backdrop');

    document.body.append(div);
  }

  private _removeCookieBackdrop(): void {
    const backdrop = document.getElementById('cookie-backdrop');
    backdrop?.remove();
  }

  closeToast(): void {
    this.showToast = false;
  }
}
