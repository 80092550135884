<div class="upload-modal">
  <div class="upload-modal-header">
    <div class="flex items-center">
      <span class="upload-modal-header-text">Job Details</span>
      <img alt="close_icon" src="assets/close-icon.svg" (click)="closeModal()" class="w-3 h-3 flex cursor-pointer" />
    </div>
    <span class="text-sm font-normal text-gray-500"> Enter the details for the job you want to advertise </span>
  </div>

  <div class="upload-modal-content">
    <div class="form" id="applyForm">
      <ng-container *ngIf="!isFormSubmitted">
        <div [formGroup]="formGroup" class="form_content">
          <app-input
            class="form_content_input"
            label="Job Title*"
            formControlName="title"
            i18n-label
            placeholder="e.g. Senior Software Eningeer"
            i18n-placeholder
            ngDefaultControl
          >
          </app-input>
          <app-input
            class="form_content_input"
            label="Job Description*"
            formControlName="description"
            i18n-label
            placeholder="Describe the main responsibilities and requirements"
            i18n-placeholder
            ngDefaultControl
            [isCustomSize]="true"
          >
          </app-input>

          <app-primary-button
            i18n-label
            label="Generate Job Ad"
            [disabled]="!formValid"
            [loading]="false"
            (onClick)="generateJobAd()"
          ></app-primary-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
