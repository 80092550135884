import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CandidateListItem } from './candidate-list/models';
import { CandidatesService } from '../../service/candidates.service';
import { Candidate } from '../models/candidate.model';
import { Observable, catchError, finalize, of, switchMap, tap, timer } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { EditCandidateComponent } from './edit-candidate/edit-candidate.component';
import { EditCandidateFieldsConfig } from './edit-candidate/edit-cadidate.config';
import { ToastService } from 'src/app/shared/helper-services/toast.service';

@Component({
    selector: 'app-candidate',
    templateUrl: './candidate.component.html',
    styleUrl: './candidate.component.scss',
    standalone: false
})
export class CandidateComponent implements OnInit,OnChanges {
  candidateId: string = '';
  candidateData: Candidate | null = null;
  selectedIndex: number = -1;
  @Input() public candidateFromJob?: string;
  @Input() showCloseButton: boolean = true;

  @Output() closeEvent = new EventEmitter<any>();

  @ViewChild('candidateContentMain', { static: false }) candidateContentMain: | ElementRef | undefined;
  @ViewChildren('descriptionRef') descriptionRefs!: QueryList<ElementRef>;
  
  heights: number[] = [];
  isLoading = true;
  private currentRequestId = 0;
  projectArray: { title: string; content: string }[] = [];
  awardsAndHonors: { title: string; content: string }[] = [];
  references: { title: string; content: string }[] = [];
  affiliations: { title: string; content: string }[] = [];
  hobbies: { title: string; content: string }[] = [];

  constructor(
    private candidatesService: CandidatesService,
    private _router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const routeCandidateId = params.get('candidateId') || '';
        if (routeCandidateId && routeCandidateId !== this.candidateId) {
        this.candidateId = routeCandidateId;
        this._loadCandidateOrganizationData(routeCandidateId);
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.descriptionRefs) {
      this.descriptionRefs.forEach((descriptionRef, index) => {
        const height = descriptionRef.nativeElement.offsetHeight;
        this.heights[index] = height;
      });
      this.cdRef.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['candidateFromJob'] && changes['candidateFromJob'].currentValue) {
      this.candidateId = changes['candidateFromJob'].currentValue;
      this._loadCandidateJobData(this.candidateId);
    }
  }

  openCandidateEditModal(
    section: 'contact' | 'skills' | 'jobs' | 'certAndTrainings' | 'volunteerExperiences' | 'educations' | 'projects' | 'awardsAndHonors' | 'references' | 'affiliations' | 'hobbies',
    index?: number
  ) {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const config = EditCandidateFieldsConfig[section];
    let fieldsWithValues: any[];

    const sectionArrayMapping: { [key: string]: { title: string; content: string }[] } = {
      projects: this.projectArray,
      awardsAndHonors: this.awardsAndHonors,
      references: this.references,
      affiliations: this.affiliations,
      hobbies: this.hobbies,
    }
  
    if (sectionArrayMapping[section] && typeof index === 'number') {
      const itemArray = sectionArrayMapping[section];
      const item = itemArray[index];
      if (!item) {
        console.error(`No item found for section ${section} at index ${index}`);
        return;
      }
      fieldsWithValues = config.fields.map((field) => ({ ...field, value: item[field.name as keyof typeof item], }));
    } else {
      fieldsWithValues = config.fields.map((field) => {
        let path = field.path;
        const sections = ['jobs', 'skills', 'certAndTrainings', 'volunteerExperiences', 'educations'];
        if (typeof index === 'number' && path && sections.includes(section)) {
          path = path.replace('[0]', `[${index}]`);
        }
        let rawValue = this.getValueFromPath(this.candidateData, path!);
        if (field.transformFrom) {
          rawValue = field.transformFrom(rawValue);
        }
        return { ...field, value: rawValue, path };
      });
    }
    
    const dialogRef = this.dialog.open(EditCandidateComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      data: {
        modalTitle: config.modalTitle,
        modalSubText: config.modalSubText,
        fields: fieldsWithValues,
        candidateData: this.candidateData,
        section,
        index,
      },
      disableClose: true,
    });
  
    dialogRef.afterClosed().subscribe((updatedData) => {
      if (updatedData && this.candidateData) {
        if (sectionArrayMapping[section] && typeof index === 'number') {
          const itemArray = sectionArrayMapping[section];
          const candidateSection = section;
          itemArray[index] = {
            title: updatedData.title,
            content: updatedData.content,
          };
          const updatedSection: any = {};
          itemArray.forEach((item) => {
            updatedSection[item.title] = item.content;
          });
          this.candidateData[candidateSection] = updatedSection;
          } else {
          this.candidateData = { ...updatedData };
        }
        if(this.candidateData){
          this.candidateData.id = this.candidateData?.cvId
          this.candidatesService.updateCandidate(this.candidateData).subscribe({
            next: (result: any) => {
              this.candidateData = { ...updatedData };
              this.toastService.showToast($localize`Data successfully updated.`, 'success');
            },
            error: () => {
              this.toastService.showToast($localize`Error occured while updating.`, 'error');
            },
            complete: () => {},
          });
        }
      }
    });
  }

  getValueFromPath(obj: any, path: string): any {
    if (!path) return undefined;
    const segments = path.replace(/\[(\d+)\]/g, '.$1').split('.'); 
    let current = obj;
    for (const seg of segments) {
      if (current === undefined || current === null) return undefined;
      current = current[seg];
    }
    return current;
  }
  
  public onBack(): void {
    this._router.navigateByUrl('/hiring-portal/candidates');
  }

  public onAddNewCandidate(): void {}

  public onCandidateSelect(candidate: CandidateListItem): void {
    if (candidate.id !== this.candidateId) {
      this.candidateId = candidate.id;
      this._loadCandidateOrganizationData(candidate.id);
    }
  }

  getFullArray(level: any): boolean[] {
    return Array.from({ length: 10 }, (_, i) => i < level);
  }

  sidenavClose() {
    this.closeEvent.emit(true);
  }

  private _handleCandidateData(result: any) {
    try {
      this.candidateData = result.body || this.candidateData;
      if (this.candidateData) {
        this.candidateData.jobs = this.candidateData.jobs.map((job) =>
        this._calculateJobExperience(job)
      );
        this.projectArray = Object.entries(this.candidateData.projects).map(([title, content]) => ({ title, content }));
        this.awardsAndHonors = Object.entries(this.candidateData.awardsAndHonors).map(([title, content]) => ({ title, content }));
        this.references = Object.entries(this.candidateData.references).map(([title, content]) => ({ title, content }));
        this.affiliations = Object.entries(this.candidateData.affiliations).map(([title, content]) => ({ title, content }));
        this.hobbies = Object.entries(this.candidateData.hobbies).map(([title, content]) => ({ title, content }));
        if (this.candidateContentMain) {
          this.candidateContentMain.nativeElement.scrollTop = 0;
        }
      }
    } catch (error) {
      this.candidateData = null;  
    }
  }
  
  private _loadCandidateData(fetchCandidate: (id: string) => Observable<any>, id: string) {
    const requestId = ++this.currentRequestId;
      this.candidateData = null;
    this.isLoading = true;
  
    fetchCandidate(id).pipe(
      tap(() => this.isLoading = true),
      switchMap((result) => {
        if (requestId === this.currentRequestId) {
          this._handleCandidateData(result);
        }
        return of(null);
      }),
      catchError((error) => {
        console.error('Error loading candidate data:', error);
        return of(null); 
      }),
      finalize(() => {
        timer(200).subscribe(() => this.isLoading = false);
      })
    ).subscribe();
  }
  private _loadCandidateOrganizationData(id: string) {
    this._loadCandidateData(this.candidatesService.getCandidateById.bind(this.candidatesService), id);
  }

  private _loadCandidateJobData(id: string) {
    this._loadCandidateData(this.candidatesService.getJobCandidateById.bind(this.candidatesService), id);
  }

  private _calculateJobExperience(job: any): any {
    try {
      const end = job.endDate ? moment(job.endDate) : moment();
      const start = moment(job.startDate);
      let experience = '';
      const years = end.diff(start, 'years');
      const months = end.diff(start, 'months') % 12;

      if (years > 0) {
        experience = `${years} ${years > 1 ? 'Years' : 'Year'}`;
        if (months > 0) {
          experience += ` ${months} ${months > 1 ? 'Months' : 'Month'}`;
        }
      } else if (months > 0) {
        experience = `${months} ${months > 1 ? 'Months' : 'Month'}`;
      } else {
        experience = 'Present';
      }
      return { ...job,experience,};
    } catch (error) {
      console.error('Error calculating job experience:', error);
      return job; 
    }
  }

  public get languages(): string[] {
    if (!this.candidateData || !this.candidateData.languages) {
      return [];
    }

    const { languages: languagesData } = this.candidateData;
    const languages = Object.keys(languagesData);
    const returnValue: Array<string> = [];
    languages.forEach((language) => {
      returnValue.push(`${language} - ${languagesData[language]}`);
    });

    return returnValue;
  }

  public onEmailCopy(tooltip: MatTooltip): void {
    tooltip.show();
    setTimeout(() => {
      tooltip.hide();
    }, 1500);
  }
}
