import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-website-header',
    templateUrl: './website-header.component.html',
    styleUrls: ['./website-header.component.scss'],
    standalone: false
})
export class WebsiteHeaderComponent {
  protected baseUrl: string = '';
  protected isMobileMenuVisible: boolean = false;

  constructor(public router: Router) {}

  ngOnInit() {}

  public scrollTo(elementId: string): void {
    setTimeout(() => {
      const el = document.getElementById(elementId);
      if (!el) {
        this.scrollTo(elementId);
      } else {
        el.scrollIntoView();
        this.isMobileMenuVisible = false;
      }
    }, 100);
  }
}
