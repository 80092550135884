import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/shared/helper-services/toast.service';

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss'],
    standalone: false
})
export class ForgetPasswordComponent {
  emailForm: FormGroup;
  isSendingEmail: boolean = false;

  isToasting: boolean = true;
  toast_type: string = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastService: ToastService
  ) {
    this.emailForm = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.nullValidator],
      ],
    });
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['hiring-portal']);
    }
  }

  onSubmit(): void {
    if (this.emailForm.valid) {
      this.isSendingEmail = true;

      this.auth.forgetPass(this.emailForm.value).subscribe({
        next: (result) => {
          this.isSendingEmail = false;
          this.toastService.showToast($localize`Reset password email is successfully sent.`, 'success');
          setTimeout(() => {
            this.isToasting = false;
              this.router.navigate(['/login-portal/login']).then(success => {
              });
          }, 2500);
        },
        error: (e) => {
          this.isSendingEmail = false;
          this.toastService.showToast($localize`Something went wrong.`, 'error');
        },
        complete: () => {},
      });
    }
  }
}
