<main-box class="flex flex-col h-full gap-5 p-5 bg-white mainBox">
  <div class="flex flex-col w-full gap-2 p-4 pl-2">
    <div i18n class="text-2xl font-semibold text-osavus-black m-0">{{ title }}</div>
    <div i18n class="text-sm font-normal text-gray-500">
      {{ subTitle }}
    </div>
  </div>

  <div class="item">
    <ng-container *ngTemplateOutlet="firstSection"></ng-container>
    <div *ngFor="let section of [1, 2, 3, 4]">
      <ng-container *ngTemplateOutlet="secondSection"></ng-container>
    </div>
  </div>
</main-box>

<ng-template #firstSection>
  <div class="second-section-wrapper">
    <div *ngFor="let sectionWidth of ['30%', '25%']" class="wrapper">
      <ngx-skeleton-loader
        animation="progress"
        [theme]="{
          width: sectionWidth,
          'border-radius': '2',
          height: '10px',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #secondSection>
  <div class="second-section-wrapper">
    <div *ngFor="let sectionWidth of ['50%', '45%']" class="wrapper">
      <ngx-skeleton-loader
        animation="progress"
        [theme]="{
          width: sectionWidth,
          'border-radius': '2',
          height: '10px',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
