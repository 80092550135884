export enum Roles {
  OrgAdmin = 'org_admin',
  Recruiter = 'recruiter',
  Candidate = 'candidate',
}

export function getRoleLabel(role: Roles): string {
  const roleMap: Record<Roles, string> = {
    [Roles.OrgAdmin]: 'Organization Administrator',
    [Roles.Recruiter]: 'Recruiter',
    [Roles.Candidate]: 'Candidate',
  };
  return roleMap[role] || 'Unknown Role';
}
