import { Component, OnInit, ViewChild } from '@angular/core';
import { IListCount } from 'src/app/components/table/models/list-count.interface';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { TableComponent } from 'src/app/components/table/table.component';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { ToastService } from 'src/app/shared/helper-services/toast.service';
import { InviteMemberModalComponent } from './invite-member-modal/invite-member-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { AuthService, InvitedPerson } from 'src/app/services/auth.service';
import { Roles } from 'src/app/modules/auth/role/roles.enum';
import { RoleService } from 'src/app/modules/auth/role/user-role.service';

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrl: './members.component.scss',
    standalone: false
})
export class MembersComponent implements OnInit {
  public isLoading: boolean = true;
  public refreshTrigger: boolean = false;
  public hasInitialData: boolean = true;
  public statusRefreshTrigger: boolean = false;
  public inviteSelection!: InvitedPerson;
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  @ViewChild(ConfirmationModalComponent) confirmationModal!: ConfirmationModalComponent;

  public hasAdminRole = false;

  private baseColumns: IMatColumn[] = [
    {
      field: 'fullName',
      header: 'NAME',
      sortable: true,
      searchKey: 'fullname',
    },
    {
      field: 'email',
      header: 'EMAIL',
      sortable: true,
      searchKey: 'email',
    },
    {
      field: 'emailConfirmed',
      header: 'STATUS',
      sortable: true,
    },
    {
      field: 'registeredDate',
      header: 'JOINED DATE',
      sortable: true,
    },
  ];

  public columns: IMatColumn[] = [...this.baseColumns];

  public tableConfig: ITableConfig = {
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-createdAt,emailConfirmed',
    } as ISievePayload,
  };

  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private toastService: ToastService,
    private roleService: RoleService
  ) {}

  ngOnInit(): void {
    this.roleService.hasRole(Roles.OrgAdmin).then((hasAdminRole) => {
      if (hasAdminRole) {
        this.hasAdminRole = true;
        this.updateBaseColumns();
      }
      this.updateTableConfig();
      this.isLoading = false;
    });
  }

  private updateTableConfig(): void {
    this.tableConfig = {
      ...this.tableConfig,
      enableRowActions: this.hasAdminRole,
      enableSelection: this.hasAdminRole,
    };
  }

  private updateBaseColumns(): void {
    this.columns.push({
      field: 'administrator',
      header: 'ADMINISTRATOR',
      sortable: false,
    });
  }

  get isSelectedCandidates(): boolean {
    return this.tableComponent?.selection?.selected.length > 0;
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }

  openAddMemberModal() {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const dialogRef = this.dialog.open(InviteMemberModalComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      disableClose: true,
    });

    dialogRef.componentInstance.sendInviteOutput.subscribe(() => {
      this.refreshTrigger = !this.refreshTrigger;
    });

    dialogRef.afterClosed().subscribe(() => {
      dialogRef.componentRef?.destroy();
    });
  }

  onOpenResendModal(invitedPerson: InvitedPerson) {
    this.inviteSelection = invitedPerson;
    this.confirmationModal.openModal();
  }

  onModalConfirm() {
    this.authService.sendInvite(this.inviteSelection).subscribe({
      error: () => {
        this.toastService.showToast($localize`Failed to invite new member`, 'error');
      },
      complete: () => {
        this.refreshTrigger = !this.refreshTrigger;
      },
    });
  }

 toggleAdminRole(isOrgAdmin: boolean, email: string) {
    if (isOrgAdmin) {
      this.roleService.removeRole({email: email, role: Roles.OrgAdmin}).subscribe({
        error: () => {
          this.refreshTrigger = !this.refreshTrigger;
          this.toastService.showToast($localize`Failed to revoke administrator access for ${email}`, 'error');
        },
        complete: () => {
          this.refreshTrigger = !this.refreshTrigger;
        },
      });
    } else {
      this.roleService.addRole({email: email, role: Roles.OrgAdmin}).subscribe({
        error: () => {
          this.refreshTrigger = !this.refreshTrigger;
          this.toastService.showToast($localize`Could not grant administrator role to ${email}`, 'error');
        },
        complete: () => {
          this.refreshTrigger = !this.refreshTrigger;
        },
      });
    }
  } 
}
