import { Component } from '@angular/core';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrl: './billing.component.scss',
    standalone: false
})
export class BillingComponent {

}
