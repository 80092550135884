import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COUNTRIES } from 'src/app/shared/store/country-data-store';
import { LiteService } from 'src/app/services/lite.service';
import { Router } from '@angular/router';
import { IDropdownItem } from 'src/app/core-ui/models';
import { ToastService } from 'src/app/shared/helper-services/toast.service';
@Component({
  selector: 'app-wait-list',
  templateUrl: './wait-list.component.html',
  styleUrls: ['./wait-list.component.scss'],
})
export class WaitListComponent {
  @Output('close') closeModal = new EventEmitter();

  waitListForm: FormGroup;

  countries: IDropdownItem[] = [];
  step: string = 'step1';
  isLoading: boolean = false;
  isGenerateToasting: boolean = true;
  toast_type: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private lite: LiteService,
    public router: Router,
    private toastService: ToastService
  ) {
    this.countries = COUNTRIES.map((country) => ({
      label: country.name,
      value: country.code,
      ...country,
    }));
    this.waitListForm = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.nullValidator]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.nullValidator],
      ],
      country: ['', [Validators.required, Validators.nullValidator]],
      IsJoinNewsletter: [
        false,
        [Validators.required, Validators.nullValidator],
      ],
    });
  }

  close() {
    this.closeModal.emit();
  }

  onSubmit() {
    if (this.waitListForm.valid) {
      this.isLoading = true;
      const data = this.waitListForm.value;
      this.lite.registerToWaitList(data).subscribe({
        next: (result) => {
          this.isLoading = false;
          this.step = 'step2';
        },
        error: (e) => {
          this.isLoading = false;
          this.toastService.showToast($localize`Something went wrong, try again later`, 'error');
        },
        complete: () => {},
      });
    } else {
      this.toastService.showToast($localize`Please fill all the fields with correct values`, 'error');
    }
  }

  endMyToast(): void {
    this.isGenerateToasting = false;
  }
}
