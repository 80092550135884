import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-row-actions',
  templateUrl: './row-actions.component.html',
  styleUrl: './row-actions.component.scss',
})
export class RowActionsComponent implements OnInit {
  @Input() rowActionsTemplate!: TemplateRef<any>;
  @Input() rowActionsMenuTemplate!: TemplateRef<any>;
  @Input() data!: any[];
  @Input() isDisabled: boolean | undefined = false;
  @Output() deleteItem = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public deleteSelectedItem(): void {
    this.deleteItem.emit();
  }
}
