<span class="flex w-full justify-center items-center overflow-visible">
  <ng-container *ngIf="rowActionsTemplate">
    <ng-template [ngTemplateOutlet]="rowActionsTemplate" [ngTemplateOutletContext]="{ $implicit: data }"> </ng-template>
  </ng-container>
  <button
    class="actions-icon-button"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Icon-button with a menu"
    [ngStyle]="{
      'background-color': '#f5f5f5',
    }"
    [disabled]="isDisabled"
  >
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngIf="rowActionsMenuTemplate">
      <ng-template
        [ngTemplateOutlet]="rowActionsMenuTemplate"
        [ngTemplateOutletContext]="{ $implicit: data }"
      ></ng-template>
    </ng-container>
    <button mat-menu-item (click)="deleteSelectedItem()">
      <div class="flex">
        <svg
          class="w-6 h-6 pt-1 text-red-700 flex items-center justify-center"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
          />
        </svg>
        <span class="text-red-700 font-semibold text-sm py-1 pl-1">Delete</span>
      </div>
    </button>
  </mat-menu>
</span>
