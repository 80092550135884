import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IListCount } from 'src/app/components/table/models/list-count.interface';
import { IBulkActionItem } from 'src/app/components/table/models/bulk-action-item.interface';
import { GenerateJobModalComponent } from '../generate-job-modal/generate-job-modal.component';
import { LoadingProcessStatus, isItemProcessing } from 'src/app/shared/store/loading-process-status';

@Component({
    selector: 'app-jobs-list',
    templateUrl: './jobs-list.component.html',
    styleUrls: ['./jobs-list.component.scss'],
    standalone: false
})
export class JobsListComponent implements OnInit {
  public isLoading: boolean = true;
  public hasInitialData: boolean = true;
  public statusRefreshTrigger: boolean = false;
  public processStatus = LoadingProcessStatus;
  public openUploadModal: boolean = false;
  public isItemProcessing = isItemProcessing;

  public columns: IMatColumn[] = [
    {
      field: 'jobTitle',
      header: 'JOB POSITION',
      sortable: true,
      searchKey: 'jobtitle',
    },
    {
      field: 'appliedCount',
      header: 'APPLIED',
      sortable: false,
    },
    {
      field: 'jobLink',
      header: 'JOB LINK',
      sortable: false,
    },
    {
      field: 'externalStatus',
      header: 'JOB STATUS',
      sortable: false,
    },
    {
      field: 'createdByName',
      header: 'CREATED BY',
      sortable: true,
    },
    {
      field: 'status',
      header: 'STATUS',
      sortable: true,
    },
    { field: 'modifiedAt', header: 'LAST MODIFIED', sortable: true },
    {
      field: 'createdAt',
      header: 'JOB CREATED',
      sortable: true,
    },
  ];

  public tableConfig: ITableConfig = {
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-modifiedAt',
    } as ISievePayload,
  };

  public bulkActions: IBulkActionItem[] = [
    { label: 'Upload Existing', value: 'uploadFile', icon: 'assets/file-upload.svg' } as IBulkActionItem,
    { label: 'Generate Job Ad', value: 'generateJobAd', icon: 'assets/generate.svg' } as IBulkActionItem,
    { label: 'Create New', value: 'blankPage', icon: 'assets/add-new.svg' } as IBulkActionItem,
  ];

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {}

  redirectToJobPage(jobId: string): void {
    this.router.navigate(['hiring-portal/jobs/job', jobId]);
  }

  public onBulkAction(action: IBulkActionItem) {
    if (action.value === 'uploadFile') {
      this.openUploadModal = true;
      this.statusRefreshTrigger = true;
    } else if (action.value === 'blankPage') {
      this.router.navigate(['hiring-portal/jobs/job/newJob']);
    } else if (action.value === 'generateJobAd') {
      this.openGenerateJobAdModal();
    }
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }

  public onUploadModalOpen(isOpen: boolean): void {
    if (this.openUploadModal && !isOpen) {
      this.openUploadModal = false;
      this.statusRefreshTrigger = false;
    } else if (!this.openUploadModal) {
      this.statusRefreshTrigger = isOpen;
    }
  }

  openGenerateJobAdModal() {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const dialogRef = this.dialog.open(GenerateJobModalComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(() => {
      dialogRef.componentRef?.destroy();
    });
  }
}
