<div class="candidate-meta-data">
  <div class="candidate-meta-data-info">
    <div class="candidate-meta-data-info-initials" *ngIf="!imageSrc">{{ data?.initials }}</div>
    <img class="candidate-meta-data-info-profile" *ngIf="imageSrc" [src]="imageSrc" alt="Candidate Image" />

    <div class="candidate-meta-data-info-basic">
      <span class="candidate-meta-data-info-basic-name">{{ name }}</span>
      <span class="flex gap-1">
        <span class="candidate-position">{{ position }}</span>
        <span class="candidate-company">&centerdot;</span>
        <span class="candidate-company">{{ company }}</span>
      </span>
    </div>
    <div class="grow"></div>
    <div class="candidate-meta-data-info-badges">
      <app-download-pdf
        [ngClass]="'candidate-meta-data-info-badges-item'"
        [id]="data?.orgCandidateId!"
        [type]="'cv'"
        [title]="'Download original CV'"
      ></app-download-pdf>
      <div
        *ngIf="data?.contact?.linkedIn"
        class="candidate-meta-data-info-badges-item"
        role="button"
        (click)="openLinkedInPage()"
      >
        <img src="assets/linked-in.svg" />
      </div>
      <div
        *ngIf="data?.contact?.github"
        class="candidate-meta-data-info-badges-item"
        role="button"
        (click)="openGitHubPage()"
      >
        <img src="assets/git.svg" />
      </div>
    </div>
  </div>
  <div class="flex items-center px-4">
    <div class="candidate-meta-data-skills">
      <span
        class="candidate-meta-data-skills-pill"
        *ngFor="let item of skils"
        >{{ item }}</span
      >
      <span class="candidate-meta-data-skills-rest" *ngIf="restSkillsNo">
        + {{ restSkillsNo }}</span
      >
    </div>
    <div class="grow"></div>
    <!-- <div>
      <span class="candidate-meta-data-status">{{ status }}</span>
    </div> -->
  </div>
</div>
