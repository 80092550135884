import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { IDropdownItem } from '../models';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
    standalone: false
})
export class SelectComponent {
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() formControlName: string = '';
  @Input() disabled: boolean = false;
  @Input() options: IDropdownItem[] = [];
  @Input() selected: any = '';
}
