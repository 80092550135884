import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { marked } from 'marked';

/**
 * Use the `marked` JS library (https://github.com/markedjs/marked) to convert the contents
 * of the decorated element from Markdown into HTML.
 * Install marked into your Angular project to make it available - `npm install --save marked`
 * create an element in your component.html and write some Markdown in it! E.g:
 * ```
 * <div appMarked>
 * # I am a header!
 *
 * I am a paragraph!
 * </div>
 * ```
 */
@Directive({
    selector: '[appMarked]',
    standalone: false
})
export class MarkedDirective implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    // deliberate use of innerHTML because we might have HTML and markdown
    // mixed together
    const markText = this.elementRef.nativeElement.innerHTML;
    if (markText && markText.length > 0) {
      const renderer = new marked.Renderer();
      renderer.heading = (text, level) => {
        return `<h1 style="color: #0A3A70">${text}</h1>`; 
      };
      const markdownHtml = marked(markText, { renderer });
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'innerHTML',
        markdownHtml,
      );
    }
  }
}
