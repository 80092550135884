<app-loading *ngIf="isLoading" />
<div class="background">
  <div class="outer-container">
    <div class="inner-container">
      <div class="header">
        <div class="header_content-wrapper">
          <div class="header_content">
            <div class="header_content_title">{{ jobData?.jobTitle }}</div>
            <div class="header_content_description">
              <span class="header_content_description_job-link">{{ jobData?.companyName }}</span>
              <ng-container *ngIf="jobData?.location">
                <span class="header_content_description_divider">|</span>
                <span class="header_content_description_label">{{ jobData?.location }}</span>
              </ng-container>
              <ng-container *ngIf="jobData?.jobType">
                <span class="header_content_description_divider">|</span>
                <span class="header_content_description_label">{{ jobData?.jobType }}</span>
              </ng-container>
              <ng-container *ngIf="jobData?.salary">
                <span class="header_content_description_divider">|</span>
                <span class="header_content_description_label">{{ jobData?.salary }}</span>
              </ng-container>
            </div>
          </div>

          <div class="header_button" *ngIf="!isFormSubmitted">
            <app-primary-button
              [label]="isJobActive ? 'Apply Now' : 'Not Published'"
              [disabled]="!isJobActive"
              (onClick)="scrollToForm()"
            ></app-primary-button>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content_section" *ngIf="jobData?.companyDescription">
          <div class="content_section_title">Company Overview</div>
          <div class="content_section_paragraph">
            {{ jobData?.companyDescription }}
          </div>
        </div>
        <div class="content_section" *ngIf="jobData?.jobSummary">
          <div class="content_section_title">Position Overview</div>
          <div class="content_section_paragraph">
            {{ jobData?.jobSummary }}
          </div>
        </div>
        <div class="content_section-small" *ngIf="jobData?.jobResponsibilities?.length">
          <div class="content_section_title">Key Responsibilities</div>
          <ul class="content_section_list">
            <li *ngFor="let item of jobData?.jobResponsibilities" class="content_section_list_item">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="content_section-small" *ngIf="jobData?.skillTags?.length">
          <div class="content_section_title">Qualifying Skills</div>
          <ul class="content_section_list">
            <li *ngFor="let item of jobData?.skillTags" class="content_section_list_item">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="content_section-small" *ngIf="jobData?.benefitsAndCompensation?.length">
          <div class="content_section_title">Why {{ jobData?.companyName }}?</div>
          <ul class="content_section_list">
            <li *ngFor="let item of jobData?.benefitsAndCompensation" class="content_section_list_item">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="content_section" *ngIf="jobData?.languages">
          <div class="content_section_title">Language</div>
          <ul class="content_section_list">
            <div *ngFor="let item of jobData?.languages | keyvalue" class="content_section_list_item">
              <li *ngIf="item.value"> {{ item.key }}: {{ item.value }}</li>
              <li *ngIf="!item.value">{{ item.key }} </li>
            </div>
          </ul>
        </div>
        <div class="form" id="applyForm">
          <ng-container *ngIf="!isFormSubmitted; else successMessage">
            <div class="form_header">
              <div class="form_header_title">Application Form</div>
              <span class="form_header_label">All fields are required.</span>
            </div>
            <div [formGroup]="formGroup" class="form_content">
              <app-input
                class="form_content_input "
                label="Name*"
                formControlName="name"
                i18n-label
                placeholder="Your full name"
                [maxLength]="lengthType.MEDIUM"
                i18n-placeholder
                ngDefaultControl
              >
              </app-input>
              <app-input
                class="form_content_input"
                label="Email*"
                formControlName="email"
                i18n-label
                placeholder="email@example.com"
                [maxLength]="lengthType.MEDIUM"
                i18n-placeholder
                ngDefaultControl
              >
              </app-input>
              <app-upload
                class="form_content_input"
                [config]="cvUploadConfig"
                (onChange)="onFileChange($event)"
                (onRemove)="onFileRemove($event)"
              >
              </app-upload>
              <span>
                <span class="form_content_terms-of-use">By applying, you agree with </span>
                <a
                  class="form_content_terms-of-use_link"
                  href="https://www.osavus.com/terms-and-conditions"
                  target="_blank"
                  >Osavus Terms of Use
                </a>
              </span>
              <app-primary-button
                i18n-label
                label="Submit your application"
                (onClick)="onJobApply()"
                [disabled]="!formValid"
                [loading]="false"
              ></app-primary-button>
            </div>
          </ng-container>
          <ng-template #successMessage>
            <div class="form_success-message">
              <div class="form_success-message_inner">
                <label class="form_success-message_title">Thank you!</label>
                <label class="form_success-message_label">Your application has been submitted. Good luck!</label>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="footer">
        <div class="footer_row-outer">
          <div class="footer_logo">
            <span class="footer_logo_label">POWERED BY</span>
            <img src="assets/full-Logo.svg" alt="logo_color" class="footer_logo_image" (click)="navigateHome()" />
          </div>
        </div>
        <div class="footer_row-outer">
          <span class="footer_copyright">
            <span class="footer_copyright_label">Copyright &copy; {{ currentYear }} Osavus Ltd.</span>
            <a class="footer_copyright_link" href="https://www.osavus.com/terms-and-conditions"> Terms of Use </a>
            <a class="footer_copyright_link" href="https://www.osavus.com/privacy-policy"> Privacy Policy </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
