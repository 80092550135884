import { Component, OnInit } from '@angular/core';
import { AuthService, OverviewData } from 'src/app/services/auth.service';
@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    standalone: false
})
export class OverviewComponent implements OnInit {
  overviewData?: OverviewData;

  constructor(
    private authService: AuthService,
  ){}

  ngOnInit(): void {
    this.authService.overview().subscribe({
      next: (data: any) => {
        this.overviewData = data.body;
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      }
    });
  }
}
