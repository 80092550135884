import { Component } from '@angular/core';
import { MarkdownParserService } from 'src/app/markdown-parser.service';
import { PolicyService } from 'src/app/services/cms/policy.service';
import { IContentPage } from 'src/app/shared/models/cms/ContentPage.model';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrl: './cookie-policy.component.scss'
})
export class CookiePolicyComponent {
  protected contentPage: IContentPage | undefined;
  constructor(
    private policyService: PolicyService,
    private markdownParser: MarkdownParserService,
  ) {}

  ngOnInit() {
    this.getContent();
  }

  getContent = () =>
    this.policyService.getCookiePolicy().subscribe((result) => {
      this.contentPage = result.body?.data.attributes;
      this.contentPage!.Content = this.markdownParser.parse(
        this.contentPage?.Content ?? '',
      ) as string;
    });
}
