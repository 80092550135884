<div class="container">
  <div class="grid-two-columns">
    <div class="card">
      <p class="card-title">Current Plan</p>
      <h2 class="card-header">Basic</h2>
      <p class="card-subtitle">$20/user per month</p>

      <app-button
        class="buttons"
        i18n-label
        label="Manage plan"
        type="custom-white-classic"
      >
        Manage plan
      </app-button>
    </div>
    <div class="card">
      <p class="card-title">Plan Size</p>
      <h2 class="card-header">5 users</h2>
      <p class="card-subtitle">3/5 active users</p>

      <app-button
      class="buttons"
      i18n-label
        label="Add users"
        type="custom-white-classic"
      >
        Add users
      </app-button>
    </div>
  </div>
  <div class="card">
    <p class="card-title">Payment method</p>
    <h2 class="card-header">Visa ending in 0687</h2>
    <p class="card-subtitle">3/5 active users</p>

    <app-button
      class="buttons"
      i18n-label
      label="Change payment method"
      type="custom-white-classic"
    >
      Change payment method
    </app-button>
  </div>
  <div class="card">
    <p class="card-title">Payment history</p>
    <h2 class="card-header">Invoices</h2>
    <div class="mt-4">
      <div class="invoice-header">
        <div>Date</div>
        <div>Total</div>
        <div>Status</div>
        <div class="text-right">Actions</div>
      </div>
      <div class="invoice-row">
        <div>22 Aug 2024</div>
        <div>US$21.80</div>
        <div>Paid</div>
        <div>
          <button class="action-button">View invoice</button>
        </div>
      </div>
    </div>
  </div>
</div>
