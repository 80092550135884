import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'osavus-modal',
    templateUrl: './osavus-modal.component.html',
    styleUrls: ['./osavus-modal.component.scss'],
    standalone: false
})
export class OsavusModalComponent {
  @Input() title: string = '';
  @Output('close') closeModal = new EventEmitter();

  close() {
    this.closeModal.emit();
  }
}
