import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import { GlobalUploadModalComponent } from 'src/app/components/global-upload-modal/global-upload-modal.component';

@Component({
  selector: 'app-create-job-modal',
  templateUrl: './create-job-modal.component.html',
  styleUrl: './create-job-modal.component.scss'
})
export class CreateJobModalComponent implements OnInit{
  @Input() label: string = 'Create Job';
  @Input() icon: string = '';
  @Input() styleType: 'primary' | 'secondary' = 'primary';
  @Input() openModal: boolean = false;

  @Output() onJobUpload = new EventEmitter<any>();

  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    if(this.openModal)
      {
        this.openCreateJobAdModal();
      }
  }

  openCreateJobAdModal() {
    this.onJobUpload.emit(true);

    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const dialogRef = this.dialog.open(GlobalUploadModalComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      data: {
        uploadUrl: `${environment.link}/job/upload`,
        modalTitle: 'Upload Job File',
        modalSubText: 'Upload a job-related file.',
        allowedExtensions: '.pdf,.doc,.docx',
        maxFileSize: 10485760,
        modalType: 'job'
      },
      disableClose: true,
    });
  
    dialogRef.afterClosed().subscribe(() => {
      this.onJobUpload.emit(false);
      dialogRef.componentRef?.destroy();
    });
  }
}
