import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CandidatesService } from 'src/app/modules/hiring-portal/modules/candidates/service/candidates.service';
import { FileService } from 'src/app/shared/helper-services/file.service';

@Component({
  selector: 'app-open-pdf',
  templateUrl: './open-pdf.component.html',
  styleUrl: './open-pdf.component.scss',
})
export class OpenPdfComponent implements OnInit {
  @Input() id!: string;

  constructor(
    private _candidatesService: CandidatesService,
    private _fileService: FileService,
  ) {}

  ngOnInit(): void {}

  public openPdf(id: string): void {
    this._candidatesService
      .getCandidateFile(id)
      .subscribe((response: HttpResponse<Blob>) => {
        this._fileService.openPdfInNewTab(response.body!);
      });
  }
}
