import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    standalone: false
})
export class ToastComponent {
  @Input() type: string = '';
  @Input() message: string = '';
  @Output() closing = new EventEmitter<void>();

  close() {
    this.closing.emit();
  }
}
