import { Component } from '@angular/core';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss'],
    standalone: false
})
export class PricingComponent {
  priceFrequent: string = 'monthly';
  features_lib: any = [
    {
      icon: 'credit-card.svg',
      name: $localize`Access to basic AI matching`,
    },
    {
      icon: 'face-grin-stars.svg',
      name: $localize`Job posting and search`,
    },
    {
      icon: 'file-shield.svg',
      name: $localize`Basic analytics dashboard`,
    },
    {
      icon: 'clipboard-check2.svg',
      name: $localize`Advanced AI matching`,
    },
    {
      icon: 'fire.svg',
      name: $localize`CV analysis and comparison`,
    },
    {
      icon: 'file-chart-bar.svg',
      name: $localize`Enhanced analytics dashboard`,
    },
    {
      icon: 'message-caption.svg',
      name: $localize`Customizable AI tools`,
    },
    {
      icon: 'clipboard-check2.svg',
      name: $localize`Real-time matching`,
    },
  ];
  pricing: any = {
    annulaAction: $localize`Go to annual plan`,
    button: $localize`Get started`,
    annualDescription: $localize`USD per month, paid annually`,

    plans: [
      {
        title: $localize`Starter`,
        monthlyPrice: '25',
        yearlyPrice: '19',

        link: '',
        features: [
          {
            item: this.features_lib[0],
            active: 1,
          },
          {
            item: this.features_lib[1],
            active: 1,
          },
          {
            item: this.features_lib[2],
            active: 1,
          },
          {
            item: this.features_lib[3],
            active: 0,
          },
          {
            item: this.features_lib[4],
            active: 0,
          },
          {
            item: this.features_lib[5],
            active: 0,
          },
          {
            item: this.features_lib[6],
            active: 0,
          },
          {
            item: this.features_lib[7],
            active: 0,
          },
        ],
      },
      {
        title: $localize`Professional`,
        monthlyPrice: '79',
        yearlyPrice: '69',

        link: '',
        features: [
          {
            item: this.features_lib[0],
            active: 1,
          },
          {
            item: this.features_lib[1],
            active: 1,
          },
          {
            item: this.features_lib[2],
            active: 1,
          },
          {
            item: this.features_lib[3],
            active: 1,
          },
          {
            item: this.features_lib[4],
            active: 1,
          },
          {
            item: this.features_lib[5],
            active: 0,
          },
          {
            item: this.features_lib[6],
            active: 0,
          },
          {
            item: this.features_lib[7],
            active: 0,
          },
        ],
      },
      {
        title: $localize`Enterprise`,
        priceAlt: 'Let’s talk.',
        annulaAction: $localize`Contact Sales`,
        button: $localize`Contact us`,
        description: $localize`Bespoke pricing available`,

        link: '',
        features: [
          {
            item: this.features_lib[0],
            active: 1,
          },
          {
            item: this.features_lib[1],
            active: 1,
          },
          {
            item: this.features_lib[2],
            active: 1,
          },
          {
            item: this.features_lib[3],
            active: 1,
          },
          {
            item: this.features_lib[4],
            active: 1,
          },
          {
            item: this.features_lib[5],
            active: 1,
          },
          {
            item: this.features_lib[6],
            active: 1,
          },
          {
            item: this.features_lib[7],
            active: 1,
          },
        ],
      },
    ],
  };
  items: [] = [];

  constructor() {}

  toggleFrequent = () => {
    this.priceFrequent =
      this.priceFrequent === 'monthly' ? 'yearly' : 'monthly';
  };
}
