<main-box>
  <div class="flex flex-col w-full h-full gap-5 p-5 bg-gray-100 pt-[60px]">
    <div class="flex flex-col w-full gap-2 p-4">
      <div i18n class="text-2xl font-semibold text-osavus-black m-0">Jobs</div>
      <div i18n class="text-sm font-normal text-gray-500">
        Creating a job post is as simple as telling us what position you need filled.
      </div>
    </div>

    <ng-container *ngIf="hasInitialData || statusRefreshTrigger; else emptyList">
      <div class="w-full h-full overflow-hidden">
        <app-table
          [columns]="columns"
          [endPoint]="'job'"
          [tableConfig]="tableConfig"
          [statusRefreshTrigger]="statusRefreshTrigger"
          (listCountUpdated)="onListCountUpdated($event)"
        >
          <ng-template tableCustomCell="jobTitle" let-item>
            <span
              class="cursor-pointer text-osavus-blue-50 hover:text-osavus-primary-70 font-semibold custom-width"
              (click)="redirectToJobPage(item.id)"
              >{{ item.jobTitle }}</span
            >
          </ng-template>

          <ng-template tableCustomCell="jobLink" let-item>
            <div class="flex">
              <item-copy [value]="item.jobLink" [link]="item.jobLink" />
            </div>
          </ng-template>

          <ng-template tableCustomCell="createdByName" let-item>
            <item-initials-and-link [initials]="item.createdByInitials" [value]="item.createdByName" />
          </ng-template>

          <ng-template tableCustomCell="createdAt" let-item>
            <span class="date">{{ item.createdAt | date: 'MMM d y' }}</span>
          </ng-template>
          <ng-template tableCustomCell="modifiedAt" let-item>
            <span class="date">{{ item.modifiedAt | date: "MMM d y hh:mm a'" }}</span>
          </ng-template>

          <ng-template #status let-element let-column="column" let-columnsTemplate="columnsTemplate">
            <app-process-skeleton
              [field]="column.field"
              [status]="element.status"
              [columnsTemplate]="columnsTemplate"
            ></app-process-skeleton>
          </ng-template>

          <ng-template #rowActions let-data>
            <app-download-pdf
              [ngClass]="'mr-3 actions-icon-button'"
              [id]="data.id"
              [type]="'job'"
              [title]="'Download original job ad'"
              [isDisabled]="isItemProcessing(data.status) || !data.fileName"
            ></app-download-pdf>
          </ng-template>

          <ng-template #tableActions>
            <app-dropdown-button
              chevronPosition="right"
              label="Create job"
              [items]="bulkActions"
              (onItemClick)="onBulkAction($event)"
              [customColor]="true"
            ></app-dropdown-button>
          </ng-template>
        </app-table>
      </div>
    </ng-container>

    <ng-template #emptyList>
      <div *ngIf="!isLoading" class="flex flex-col items-center w-full gap-3 overflow-auto">
        <img src="assets/create-job.svg" alt="empty_job_list" class="h-2/4"/>
        <p class="empty-list-text">No active jobs detected.</p>

        <app-button
          type="empty-list-add-gradient"
          i18n-label
          label="Generate Job Ad"
          (onClick)="openGenerateJobAdModal()"
        ></app-button>

        <app-button
          type="empty-list-add-transparent"
          i18n-label
          label="Upload Existing Job Ad"
          (onClick)="onBulkAction(bulkActions[0])"
        ></app-button>

        <app-button
          type="empty-list-add-transparent"
          i18n-label
          label="Create New Job Ad"
          (onClick)="onBulkAction(bulkActions[2])"
        ></app-button>
      </div>
    </ng-template>
  </div>
  <app-loading *ngIf="isLoading"></app-loading>
</main-box>

<div *ngIf="openUploadModal">
  <app-create-job-modal [openModal]="true" (onJobUpload)="onUploadModalOpen($event)"></app-create-job-modal>
</div>
