<div class="fixed inset-0 z-[98] transition-opacity bg-gray-500">
  <img
    [src]="
      step === 'step1' ? 'assets/Early-access1.jpg' : 'assets/Early-access2.jpg'
    "
    class="object-cover w-full h-full"
    alt=""
  />
</div>

<div class="fixed inset-0 z-[99] w-screen overflow-y-auto">

  <div
    class="flex items-center justify-center min-h-full p-6 text-center sm:justify-start sm:ml-16 sm:p-0"
  >
    <div class="flex flex-col w-full gap-4 sm:w-96">
      <img
        class="hidden h-8 w-[169px] md:flex"
        src="assets/full-logo-white.svg"
        alt="Osavus"
      />

      <ng-container *ngIf="step === 'step1'">
        <form [formGroup]="waitListForm" ngNativeValidate>
          <div class="waitlist-box">
            <div i18n class="waitlist-title">Secure early access</div>

            <div class="w-full">
              <app-input
                id="fullName"
                name="fullName"
                label="Full name"
                i18n-label
                placeholder="Write full name here ..."
                i18n-placeholder
                formControlName="fullName"
                ngDefaultControl
              >
              </app-input>
            </div>

            <div class="w-full">
              <app-select
                id="country"
                name="country"
                label="Country"
                i18n-label
                formControlName="country"
                [options]="countries"
                [selected]="waitListForm.value.country"
                ngDefaultControl
              >
              </app-select>
            </div>

            <div class="w-full">
              <app-input
                id="email"
                name="email"
                label="Email"
                i18n-label
                placeholder="Write email here ..."
                i18n-placeholder
                formControlName="email"
                ngDefaultControl
              >
              </app-input>
            </div>

            <div class="flex">
              <input
                id="IsJoinNewsletter"
                name="IsJoinNewsletter"
                type="checkbox"
                formControlName="IsJoinNewsletter"
                class="w-4 h-4 mt-1 border-gray-300 rounded cursor-pointer bg-gray-50 text-osavus-blue-80 focus:ring-blue-600"
              />
              <label
                i18n
                for="IsJoinNewsletter"
                class="block ml-2 text-xs leading-6 text-gray-500"
              >
                Join the newsletter to stay up to date
              </label>
            </div>

            <div class="w-full">
              <app-button
                type="custom-red"
                i18n-label
                label="Join the early access list"
                [isLoading]="isLoading"
                (onClick)="onSubmit()"
              ></app-button>
            </div>
            <div class="w-full">
              <app-button
                type="custom-white"
                i18n-label
                label="Back to website"
                (onClick)="router.navigate(['./'])"
              ></app-button>
            </div>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="step === 'step2'">
        <div class="waitlist-box">
          <div i18n class="w-full waitlist-title">You’re on the list!</div>

          <div i18n class="w-full text-base font-normal text-gray-500">
            Thank you for your interest. We’ll send you an update when
            registration opens.
          </div>

          <div class="w-full mt-4">
            <app-button
              type="custom-white"
              i18n-label
              label="Back to website"
              (onClick)="router.navigate(['./'])"
            ></app-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
