import { NgModule } from '@angular/core';
import { CandidatesComponent } from './components/candidates/candidates.component';
import { RouterModule } from '@angular/router';
import { CandidatesRoutingModule } from './candidates-routing.module';
import { CandidatesUploadModalComponent } from './components/candidates-upload-modal/candidates-upload-modal.component';
import { CommonModule } from '@angular/common';
import { CoreUIModule } from 'src/app/core-ui/core-ui.module';
import { CandidatesListComponent } from './components/candidates-list/candidates-list.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MaterialModule } from 'src/app/material.module';
import { CandidateListComponent } from './components/candidate/candidate-list/candidate-list.component';
import { CandidateListItemComponent } from './components/candidate/candidate-list/candidate-list-item';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CandidateComponent } from './components/candidate/candidate.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { CandidateMetaDataComponent } from './components/candidate/candidate-meta-data';

@NgModule({
  declarations: [
    CandidatesComponent,
    CandidatesUploadModalComponent,
    CandidatesListComponent,
    CandidateListComponent,
    CandidateListItemComponent,
    CandidateComponent,
    CandidateMetaDataComponent
  ],
  imports: [
    RouterModule,
    CandidatesRoutingModule,
    CommonModule,
    CoreUIModule,
    ComponentsModule,
    ClipboardModule,
    MaterialModule,
    UploaderModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    ScrollingModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    MatTooltip
  ],
  exports: [CandidatesUploadModalComponent, CandidateComponent],
  bootstrap: [CandidatesComponent],
})
export class CandidatesModule {}
