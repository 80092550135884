import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ScrollingModule, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CandidateListItemComponent } from './candidate-list-item';
import { CandidateListItem } from './models';
import { CandidatesService, ICandidateData } from '../../candidates.service';
import { Subject, filter, fromEvent, map, pairwise, throttleTime } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-candidate-list',
  standalone: true,
  imports: [
    CommonModule, 
    CandidateListItemComponent, 
    ScrollingModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.scss'],
})
export class CandidateListComponent implements OnInit, AfterViewInit, OnDestroy {

  public currentSort: string = '-createdAt';
  private _candidates: Array<CandidateListItem> = [];
  private _selectedCandidateId: string = '';
  _totalRecords: number = 0;
  private destroy$ = new Subject<void>();

  @Output() select: EventEmitter<CandidateListItem> = new EventEmitter();
  @ViewChildren(CandidateListItemComponent) candidateComponents!: QueryList<CandidateListItemComponent>;
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  constructor(
    private candidatesService: CandidatesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this._selectedCandidateId = this.route.snapshot.params['candidateId'];
    this.loadCandidates();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        setTimeout(() => {
          this.scrollToSelectedCandidate();
        }, 500);
      });
  }

  ngAfterViewInit() {
    fromEvent(this.viewport.elementRef.nativeElement, 'scroll')
    .pipe(
      throttleTime(200),
      map(() => this.viewport.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1, y2]) => y2 < y1 && y2 === 0),
    )
    .subscribe(() => {});
    setTimeout(() => {
      this.scrollToSelectedCandidate();
    }, 500);
  }

  private loadCandidates(): void {
    this.candidatesService.getCandidates({ page: 1, pageSize: 100, sorts: this.currentSort })
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (result) => {
        this._candidates = result.body.map((item: ICandidateData) => {
          return {
            id: item.id,
            initials: item.initials,
            fullName: item.fullName,
            position: item.activeJobTitle,
          };
        });

        try {
          this._totalRecords = parseInt(
            result.headers.get('X-Totalcount') as string,
          );
        } catch (e) {
          this._totalRecords = this._candidates.length;
        }
        setTimeout(() => {
          this.scrollToSelectedCandidate();
        }, 500);
      },
      error: (e) => {
        this._totalRecords = 0;
        this._candidates = [];
      },
    });
  }

  public onCandidateSelect(candidate: CandidateListItem): void {
    this._selectedCandidateId = candidate.id;
    this.select.emit(candidate);
    this.destroy$.next();
    this.router.navigate(['../', candidate.id], { relativeTo: this.route });
    setTimeout(() => {
      this.scrollToSelectedCandidate();
    }, 0);
  }

  private scrollToSelectedCandidate(): void {
    if (this._selectedCandidateId) {
        const selectedComponent = this.candidateComponents.find(component => component.item?.id === this._selectedCandidateId);
        if (selectedComponent && selectedComponent.candidateItem) {
            const candidateElement = selectedComponent.candidateItem.nativeElement;
            const containerElement = candidateElement.closest('cdk-virtual-scroll-viewport') || candidateElement.closest('div[style*="overflow-y: auto"]');
            if (containerElement) {
                const containerRect = containerElement.getBoundingClientRect();
                const candidateRect = candidateElement.getBoundingClientRect();
                const offset = candidateRect.top - containerRect.top + containerElement.scrollTop;
                containerElement.scrollTo({ top: offset,behavior: 'smooth'});
            }
        }
    }
  }

  public get candidates(): Array<CandidateListItem> {
    return this._candidates;
  }

  public get selectedCandidateId(): string {
    return this._selectedCandidateId;
  }

  public sortCandidates(sort: string): void {
    this.currentSort = sort;
    this.loadCandidates();
  }

  public getSortLabel(): string {
    switch (this.currentSort) {
      case '-createdAt':
        return 'Newest';
      case 'createdAt':
        return 'Oldest';
      case '-fullName':
        return 'Name (Z - A)';
      case 'fullName':
        return 'Name (A - Z)';
      default:
        return 'Sort';
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}