import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Component({
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.scss'],
    standalone: false
})
export class DropdownButtonComponent implements OnInit {
  @Input() public label: string;
  @Input() public chevronPosition: 'left' | 'right';
  @Input() public items: { label: string; value: string; icon?: string}[];
  @Input() public disabled: boolean;
  @Input() public icon: string;
  @Input() public circleButton: boolean;
  @Output() public onItemClick: EventEmitter<{ label: string; value: string }>;
  @Input() public removeBorder: boolean = false;
  @Input() public textColor: boolean = true;
  @Input() public customColor: boolean = false;

  private _opened: boolean;

  public get opened(): boolean {
    return this._opened;
  }
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
    this.label = '';
    this.chevronPosition = 'left';
    this.items = [];
    this.disabled = false;
    this.icon = '';
    this._opened = false;
    this.circleButton = false;

    this.onItemClick = new EventEmitter<{ label: string; value: string }>();
  }

  ngOnInit() {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target != this.elementRef.nativeElement &&
        !this.elementRef.nativeElement.contains(e.target)
      ) {
        this._opened = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public get leftPosition(): boolean {
    return this.chevronPosition === 'left';
  }

  public onClick(item: { label: string; value: string }): void {
    this.onItemClick.emit(item);
    this._opened = false;
  }

  public openMenu(): void {
    this._opened = !this._opened;
  }
}
