import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ICmsResponseSingle } from '../../shared/models/cms/CmsResponse.model';
import { IContentPage } from '../../shared/models/cms/ContentPage.model';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  endpoint: string = `${environment.cms}/api`;
  constructor(private http: HttpClient) {}

  getPrivacyPolicy = () =>
    this.http.get<ICmsResponseSingle<IContentPage>>(
      `${this.endpoint}/privacy-policy?populate=*`,
      { responseType: 'json', observe: 'response' },
    );

  getTermsAndConditions = () =>
    this.http.get<ICmsResponseSingle<IContentPage>>(
      `${this.endpoint}/terms-and-condition?populate=*`,
      { responseType: 'json', observe: 'response' },
    );

  getCookiePolicy = () =>
    this.http.get<ICmsResponseSingle<IContentPage>>(
      `${this.endpoint}/cookie-policy?populate=*`,
      { responseType: 'json', observe: 'response' },
    );
}
