import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-resizable-div',
    templateUrl: './resizable-div.component.html',
    styleUrls: ['./resizable-div.component.scss'],
    standalone: false
})
export class ResizableDivComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
