<div class="invite-modal">
  <div class="invite-modal-header">
    <div class="flex items-center">
      <span class="invite-modal-header-text">Invite New Member</span>
      <img alt="close_icon" src="assets/close-icon.svg" (click)="closeModal()" class="w-3 h-3 flex cursor-pointer" />
    </div>
    <span class="text-sm font-normal text-gray-500"> Enter the details of the person you want to invite.</span>
  </div>

  <div class="invite-modal-content">
    <div class="form" id="inviteForm">
        <div [formGroup]="formGroup" class="form_content">
          <app-input
            class="form_content_input"
            label="Name*"
            formControlName="name"
            i18n-label
            placeholder="Full name"
            [maxLength]="lengthType.MEDIUM"
            i18n-placeholder
            ngDefaultControl
          >
          </app-input>
          <app-input
            class="form_content_input"
            label="Email*"
            formControlName="email"
            i18n-label
            placeholder="email@example.com"
            [maxLength]="lengthType.MEDIUM"
            i18n-placeholder
            ngDefaultControl
          >
          </app-input>
          <app-primary-button
            i18n-label
            label="Invite Member"
            [icon]="'icon-plus'"
            [disabled]="!isFormValid"
            [loading]="false"
            (onClick)="sendInvite()"
          ></app-primary-button>
        </div>
    </div>
  </div>
</div>
