import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-primary-button',
    templateUrl: './primary-button.component.html',
    styleUrls: ['./primary-button.component.scss'],
    standalone: false
})
export class PrimaryButtonComponent implements OnInit {
  @Input()
  public label: string;

  @Input()
  public icon: string;

  @Input()
  public disabled: boolean;

  @Input()
  public loading: boolean;

  @Input()
  public iconPosition: 'left' | 'right' = 'left';

  @Output()
  public onClick: EventEmitter<void>;

  constructor() {
    this.label = '';
    this.icon = '';
    this.disabled = false;
    this.loading = false;

    this.onClick = new EventEmitter<void>();
  }

  ngOnInit() {}
}
