import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private messageSubject = new BehaviorSubject<string>('');
  private typeSubject = new BehaviorSubject<string>('success');
  private isToastingSubject = new BehaviorSubject<boolean>(false);

  message$ = this.messageSubject.asObservable();
  type$ = this.typeSubject.asObservable();
  isToasting$ = this.isToastingSubject.asObservable();

  showToast(message: string, type: 'success' | 'error' = 'success', duration: number = 5000): void {
    this.messageSubject.next(message);
    this.typeSubject.next(type);
    this.isToastingSubject.next(true);

    setTimeout(() => {
      this.isToastingSubject.next(false);
    }, duration);
  }
}
