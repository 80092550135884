import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/shared/helper-services/toast.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: false
})
export class ChangePasswordComponent {
  changePassForm: FormGroup;
  isSubmittingPass: boolean = false;

  isToasting: boolean = true;
  toast_type: string = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private routerActivate: ActivatedRoute,
    private toastService: ToastService
  ) {
    this.changePassForm = this.formBuilder.group({
      password: ['', [
        Validators.required, 
        Validators.minLength(8), 
        this.auth.passwordValidator(),
      ]],
    });
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['hiring-portal']);
    }
  }

  get passwordErrors(): string | null {
    const passwordControl = this.changePassForm.get('password');
    if (passwordControl) {
      return AuthService.getPasswordValidationErrors(passwordControl);
    }
    return null;
  }

  onSubmit(): void {
    if (this.changePassForm.valid) {
      this.isSubmittingPass = true;
      let data = {
        email: this.routerActivate.snapshot.queryParams['email'],
        resetToken: this.routerActivate.snapshot.queryParams['token'].replace(
          /\s/g,
          '+',
        ),
        password: this.changePassForm.value.password,
      };
      this.auth.resetPass(data).subscribe({
        next: (result) => {
          this.isSubmittingPass = false;
          this.toastService.showToast($localize`Password has changed successfully.`, 'success');
          this.router.navigate(['login'])
        },
        error: (e) => {
          this.isSubmittingPass = false;
          this.toastService.showToast($localize`Something went wrong, try again later`, 'error');
        },
        complete: () => {},
      });
    }
  }
}
