<div class="flex flex-col items-start flex-auto w-full h-full overflow-hidden rounded-md drop-shadow-sm border">
  <div *ngIf="enableSearch || enableSelection || tableActionsTemplate" class="w-full p-4 bg-white">
    <div class="flex flex-wrap items-center" [ngClass]="enableSearch ? 'justify-between' : 'justify-end'">
      <div class="flex m-1" *ngIf="enableSearch">
        <app-search [searchTerm]="searchTerm" (searchItem)="handleSearch($event)"></app-search>
      </div>
      <div class="flex flex-wrap gap-4 m-1">
        <ng-container *ngIf="enableSelection">
          <app-dropdown-button
            *ngIf="enableRowActions"
            label="Bulk Actions"
            [items]="bulkActions"
            (onItemClick)="onBulkAction($event)"
          >
          </app-dropdown-button>
        </ng-container>
        <ng-container *ngIf="tableActionsTemplate">
          <ng-template [ngTemplateOutlet]="tableActionsTemplate"></ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="table-content">
    <app-loading *ngIf="!isProcessing && isLoading" />
    <div class="flex-grow overflow-auto">
      <table mat-table [dataSource]="data" matSort (matSortChange)="onSort($event)">
        <!-- Select -->
        <ng-container matColumnDef="select" *ngIf="enableSelection">
          <th mat-header-cell *matHeaderCellDef class="selection-column">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [disabled]="isLoading"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="selection-column">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row.id) : null"
              [checked]="selection.isSelected(row.id) && !isItemProcessing(row.status)"
              [disabled]="isItemProcessing(row.status)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Other Columns -->
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="statusTemplate; else defaultColumnsTemplate">
              <ng-template
                *ngTemplateOutlet="
                  statusTemplate;
                  context: { $implicit: element, column: column, columnsTemplate: defaultColumnsTemplate }
                "
              >
              </ng-template>
            </ng-container>
            <ng-template #defaultColumnsTemplate>
              <ng-container
                *ngTemplateOutlet="
                  getCustomTemplate(column.field) || defaultCell;
                  context: { $implicit: element, column: column }
                "
              ></ng-container>
            </ng-template>
          </td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions" *ngIf="enableRowActions">
          <th mat-header-cell *matHeaderCellDef class="actions-column">ACTIONS</th>
          <td mat-cell *matCellDef="let data" class="overflow-cell actions-column">
            <app-row-actions
              [data]="data"
              [rowActionsTemplate]="rowActionsTemplate"
              [rowActionsMenuTemplate]="rowActionsMenuTemplate"
              (deleteItem)="onDelete(data.id)"
              [isDisabled]="isItemProcessing(data.status)"
            ></app-row-actions>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="displayedColumns.length">
            <span class="text-osavus-black font-bold">No results found. </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <mat-paginator
    [length]="listCount.filteredCount"
    [pageSize]="defaultPayload.pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [disabled]="isLoading"
    (page)="onPageChange()"
  >
  </mat-paginator>
</div>
<ng-template #defaultCell let-element let-column="column">
  <span class="text-gray-500 font-semibold">
    {{ element[column.field] }}
  </span>
</ng-template>
<app-delete-confirmation
  *ngIf="openDeleteConfirmationModal"
  [selection]="selection"
  [(selectedId)]="_selectedId"
  [confirmationMessage]="confirmationMessage"
  [(openConfirmationModal)]="openDeleteConfirmationModal"
  [(isLoading)]="isLoading"
  [endPoint]="endPoint"
  (handleDelete)="handleDeleteResponse($event)"
></app-delete-confirmation>
