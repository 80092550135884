import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'item-initials-and-link',
    templateUrl: './item-initials-and-link.component.html',
    styleUrl: './item-initials-and-link.component.scss',
    standalone: false
})
export class ItemInitialsAndLinkComponent {
  @Input() initials!: string;
  @Input() value!: string;
  @Output() clicked = new EventEmitter<void>();

  public onclick() {
    this.clicked.emit();
  }
}
