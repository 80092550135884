<div class="upload-modal w-full">
  <div class="upload-modal-header">
    <div class="flex items-center">
      <span class="upload-modal-header-text">Apply New Candidates</span>
      <img alt="close_icon" src="assets/close-icon.svg" (click)="closeModal()" class="w-3 h-3 flex cursor-pointer" />
    </div>
  </div>

  <div class="upload-modal-content"> 
    <ng-container>
      <div class="w-full h-full overflow-hidden">
        <app-table
          [columns]="columns"
          [endPoint]="candidatesEndpoint"
          [tableConfig]="tableConfig"
          (listCountUpdated)="onListCountUpdated($event)"
        >
          <ng-template tableCustomCell="fullName" let-item>
            <span class="text-osavus-blue-50 font-semibold">{{ item.fullName }}</span>
          </ng-template>

          <ng-template tableCustomCell="location" let-item>
            <span class="text-gray-500 font-semibold custom-width">{{ item.location }}</span>
          </ng-template>

          <ng-template tableCustomCell="createdAt" let-item>
            <span class="text-gray-500 font-semibold">{{ item.createdAt | date: 'MMM d y ' }}</span>
          </ng-template>

          <ng-template #tableActions>
            <app-primary-button
            [label]="'Add selected candidates'"
            (click)="applySelectedCandidates()"
            [disabled]="!isSelectedCandidates"
          ></app-primary-button>
          </ng-template>
        </app-table>
      </div>
     
    </ng-container>
  </div>
  <app-loading *ngIf="isLoading"></app-loading>
</div>
