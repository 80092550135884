import { LengthType } from "src/app/shared/store/length-type";

export const EditCandidateFieldsConfig: {
  [key: string]: { 
    modalTitle: string; 
    modalSubText: string; 
    fields: {
      type: string;
      name: string;
      label: string;
      placeholder?: string;
      maxLength?: number;
      options?: any[];
      path?: string;
      transformFrom?: (value: any) => any; 
      transformTo?: (value: any) => any; 
    }[]
  }
} = {
  contact: {
    modalTitle: 'Edit Contact & Languages',
    modalSubText: 'Edit candidate contact details and languages',
    fields: [
      {
        type: 'input',
        name: 'email',
        label: 'Email:',
        placeholder: 'Write email here ...',
        path: 'contact.email',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'input',
        name: 'phone',
        label: 'Phone:',
        placeholder: 'Write phone here ...',
        path: 'contact.phone',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'multi-select',
        name: 'languages',
        label: 'Languages:',
        placeholder: 'Choose languages here ...',
        path: 'languages',
        transformFrom: (val: any) => {
          if (!val || typeof val !== 'object') return [];
          return Object.entries(val).map(([language, level]) => `${language} - ${level}`);
        },
        transformTo: (arr: string[]) => {
          const obj: any = {};
          arr.forEach(item => {
            const parts = item.split(' - ');
            if (parts.length === 2) {
              const [language, level] = parts;
              obj[language.trim()] = level.trim();
            } else {
              obj[item.trim()] = 'Good';
            }
          });
          return obj;
        }
      },
      {
        type: 'input',
        name: 'linkedIn',
        label: 'LinkedIn:',
        placeholder: 'Write LinkedIn URL here ...',
        path: 'contact.linkedIn',
        maxLength: LengthType.LONG
      },
      {
        type: 'textarea',
        name: 'summary',
        label: 'Summary :',
        placeholder: 'Write summary here',
        path: 'summary',
        maxLength: LengthType.EXTENDED
      }
    ]
  },
  skills: {
    modalTitle: 'Edit Skills',
    modalSubText: 'Edit skills data',
    fields: [
      {
        type: 'input',
        name: 'skill',
        label: 'Skill Name:',
        placeholder: 'Enter skill name...',
        path: 'skills[0].skill',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'input',
        name: 'level',
        label: 'Skill Level:',
        placeholder: 'Enter skill level...',
        path: 'skills[0].level',
        transformFrom: (val: any) => val?.toString(), 
        transformTo: (val: any) => parseInt(val, 10) 
      },
      {
        type: 'input',
        name: 'description',
        label: 'Skill Description:',
        placeholder: 'Enter skill description...',
        path: 'skills[0].description',
        maxLength: LengthType.EXTENDED
      },
      {
        type: 'select',
        name: 'skillType',
        label: 'Skill Type:',
        placeholder: 'Select skill type...',
        options: [
          { label: 'Hard', value: 'Hard' },
          { label: 'Soft', value: 'Soft' }
        ],
        path: 'skills[0].skillType'
      }
    ]
  },
  jobs: {
    modalTitle: 'Edit Jobs',
    modalSubText: 'Edit candidate job experience',
    fields: [
      {
        type: 'input',
        name: 'company',
        label: 'Company:',
        placeholder: 'Enter company name...',
        path: 'jobs[0].company',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'input',
        name: 'title',
        label: 'Job Title:',
        placeholder: 'Enter job title...',
        path: 'jobs[0].title',
        maxLength: LengthType.LONG
      },
      {
        type: 'date',
        name: 'startDate',
        label: 'Start Date:',
        placeholder: 'YYYY-MM-DD',
        path: 'jobs[0].startDate'
      },
      {
        type: 'date',
        name: 'endDate',
        label: 'End Date:',
        placeholder: 'YYYY-MM-DD',
        path: 'jobs[0].endDate'
      },
      {
        type: 'textarea',
        name: 'description',
        label: 'Job Description:',
        placeholder: 'Short description of the role...',
        path: 'jobs[0].description',
        maxLength: LengthType.MULTILINE
      }
    ]
  },
  certAndTrainings: {
    modalTitle: 'Edit Certificates and Trainings',
    modalSubText: 'Edit a specific certificate or training',
    fields: [
      {
        type: 'input',
        name: 'name',
        label: 'Name:',
        placeholder: 'Enter the certificate/training name...',
        path: 'certAndTrainings[0].name',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'input',
        name: 'issuer',
        label: 'Issuer:',
        placeholder: 'Enter the issuer...',
        path: 'certAndTrainings[0].issuer',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'date',
        name: 'dateEarned',
        label: 'Date Earned:',
        placeholder: 'YYYY-MM-DD',
        path: 'certAndTrainings[0].dateEarned'
      },
      {
        type: 'textarea',
        name: 'description',
        label: 'Description:',
        placeholder: 'Enter a brief description...',
        path: 'certAndTrainings[0].description',
        maxLength: LengthType.EXTENDED
      }
    ]
  },
  volunteerExperiences: {
    modalTitle: 'Edit Volunteer Experience',
    modalSubText: 'Edit a specific volunteer experience entry',
    fields: [
      {
        type: 'input',
        name: 'organization',
        label: 'Organization:',
        placeholder: 'Enter the organization name...',
        path: 'volunteerExperiences[0].organization',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'input',
        name: 'role',
        label: 'Role:',
        placeholder: 'Enter the volunteer role...',
        path: 'volunteerExperiences[0].role',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'date',
        name: 'dateFrom',
        label: 'Start Date:',
        placeholder: 'YYYY-MM-DD',
        path: 'volunteerExperiences[0].dateFrom'
      },
      {
        type: 'date',
        name: 'dateTo',
        label: 'End Date:',
        placeholder: 'YYYY-MM-DD',
        path: 'volunteerExperiences[0].dateTo'
      },
      {
        type: 'textarea',
        name: 'description',
        label: 'Description:',
        placeholder: 'Short description of the volunteer work...',
        path: 'volunteerExperiences[0].description',
        maxLength: LengthType.EXTENDED
      }
    ]
  },
  educations: {
    modalTitle: 'Edit Education',
    modalSubText: 'Edit a specific education entry',
    fields: [
      {
        type: 'input',
        name: 'institution',
        label: 'Institution:',
        placeholder: 'Enter institution name...',
        path: 'educations[0].institution',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'date',
        name: 'enrollmentDate',
        label: 'Enrollment Date:',
        placeholder: 'YYYY-MM-DD',
        path: 'educations[0].enrollmentDate'
      },
      {
        type: 'date',
        name: 'graduationDate',
        label: 'Graduation Date:',
        placeholder: 'YYYY-MM-DD',
        path: 'educations[0].graduationDate'
      },
      {
        type: 'textarea',
        name: 'degrees',
        label: 'Degrees:',
        placeholder: 'List degrees...',
        path: 'educations[0].degrees',
        transformFrom: (val: any) => {
          if (!Array.isArray(val)) return [];
          return val.map((item: string) => item.replace(/^\[|\]$/g, '').trim());
        },
        transformTo: (arr: string[]) => {
          return arr.map(item => `[${item}]`);
        }
      },
      {
        type: 'input',
        name: 'courseworkAndTheses',
        label: 'Coursework & Theses:',
        placeholder: 'List coursework/theses...',
        path: 'educations[0].courseworkAndTheses',
        transformFrom: (val: any) => {
          if (!Array.isArray(val)) return [];
          return val.map((item: string) => item.replace(/^\[|\]$/g, '').trim());
        },
        transformTo: (arr: string[]) => {
          return arr.map(item => `[${item}]`);
        }
      }
    ]
  },
  projects: {
    modalTitle: 'Edit Project',
    modalSubText: 'Edit project data',
    fields: [
      {
        type: 'input',
        name: 'title',
        label: 'Project Title:',
        placeholder: 'Enter project title...',
        path: 'projects[0].title',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'textarea',
        name: 'content',
        label: 'Project Description:',
        placeholder: 'Enter project description...',
        path: 'projects[0].content',
        maxLength: LengthType.EXTENDED
      }
    ]
  }, 
  awardsAndHonors: {
    modalTitle: 'Edit Award',
    modalSubText: 'Edit award data',
    fields: [
      {
        type: 'input',
        name: 'title',
        label: 'Award Title:',
        placeholder: 'Enter award title...',
        path: 'awardsAndHonors[0].title',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'textarea',
        name: 'content',
        label: 'Award Description:',
        placeholder: 'Enter award description...',
        path: 'awardsAndHonors[0].content',
        maxLength: LengthType.EXTENDED
      }
    ]
  },
  references: {
    modalTitle: 'Edit References',
    modalSubText: 'Edit references data',
    fields: [
      {
        type: 'input',
        name: 'title',
        label: 'References Title:',
        placeholder: 'Enter references title...',
        path: 'references[0].title',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'textarea',
        name: 'content',
        label: 'References Description:',
        placeholder: 'Enter references description...',
        path: 'references[0].content',
        maxLength: LengthType.EXTENDED
      }
    ]
  },  
  affiliations: {
    modalTitle: 'Edit Affiliations',
    modalSubText: 'Edit affiliations data',
    fields: [
      {
        type: 'input',
        name: 'title',
        label: 'Affiliations Title:',
        placeholder: 'Enter affiliations title...',
        path: 'affiliations[0].title',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'textarea',
        name: 'content',
        label: 'Affiliations Description:',
        placeholder: 'Enter affiliations description...',
        path: 'affiliations[0].content',
        maxLength: LengthType.EXTENDED
      }
    ]
  },  
  hobbies: {
    modalTitle: 'Edit Hobbies',
    modalSubText: 'Edit hobbies data',
    fields: [
      {
        type: 'input',
        name: 'title',
        label: 'Hobbies Title:',
        placeholder: 'Enter hobbies title...',
        path: 'hobbies[0].title',
        maxLength: LengthType.MEDIUM
      },
      {
        type: 'textarea',
        name: 'content',
        label: 'Hobbies Description:',
        placeholder: 'Enter hobbies description...',
        path: 'hobbies[0].content',
        maxLength: LengthType.EXTENDED
      }
    ]
  },
};
