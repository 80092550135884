<div class="h-full overflow-hidden">
  <div class="fixed top-0 z-50 w-full">
    <hiring-header></hiring-header>
  </div>
  <div class="relative h-full flex">
    <div class="flex h-full">
      <!-- <app-sidebar></app-sidebar> -->
    </div>
    <div class="w-full h-full">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
