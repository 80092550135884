import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, concat, concatMap, debounceTime, forkJoin, from, of, tap } from 'rxjs';
import { IJobSkill, JobsService, SkillType } from '../../services/jobs.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/shared/helper-services/toast.service';
import { LengthType } from 'src/app/shared/store/length-type';

@Component({
    selector: 'app-criteria',
    templateUrl: './criteria.component.html',
    styleUrl: './criteria.component.scss',
    standalone: false
})
export class CriteriaComponent implements OnInit, OnDestroy {
  jobId: string = '';
  jobSkillForm: FormGroup;
  jobSkills!: IJobSkill[];
  private autosaveSubscription: Subscription | undefined;
  private autosaveTimer: any;
  showSaved = false;
  showSaving = false;
  initialLoad = true;
  initialLoadStatus = true;
  externalStatus: string = '';
  private savingInProgress = false;
  private autosaveInProgress = false; 
  isGenerateToasting: boolean = true;
  isVerifyToasting: boolean = true;
  toast_type: string = '';
  isUpdatingJob: boolean = false;

  public lengthType = LengthType;

  @ViewChildren('softSkillsInput')softSkillsInputs!: QueryList<ElementRef>;
  @ViewChildren('hardSkillsInput')hardSkillsInputs!: QueryList<ElementRef>;

  @Output() initialLoadStatusChanged = new EventEmitter<boolean>();
  @Output() showSavingChanged = new EventEmitter<boolean>();
  @Output() showSavedChanged = new EventEmitter<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private jobsService: JobsService,
    private route: ActivatedRoute,
    private toastService: ToastService
  ){
    this.jobSkillForm = this.formBuilder.group({
      softSkills: this.formBuilder.array([]),
      hardSkills: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params['jobId'];
    this.getJobSkillsWithId(this.jobId);
    this.autosaveSubscription = this.jobSkillForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (this.initialLoad) {
        this.initialLoad = false;
        return;
      }
      if (this.savingInProgress || this.autosaveInProgress) return;
      if (!this.jobSkillForm.dirty) return;
    
      this.initialLoadStatus = false;
      this.showSaving = true;
      this.showSaved = false;
      this.initialLoadStatusChanged.emit(this.initialLoadStatus);
      this.showSavingChanged.emit(this.showSaving);
      this.showSavedChanged.emit(this.showSaved);
          this.resetAutosaveTimer();
    });
  }
  

  private resetAutosaveTimer(immediate: boolean = false): void {
    if (this.autosaveTimer) {
      clearTimeout(this.autosaveTimer);
    }
    if (immediate) {
      this.saveAllSkills();
    } else {
      this.autosaveTimer = setTimeout(() => {
        this.saveAllSkills();
      }, 4000);
    }
  }

  addHardSkill(): void {
    const skillGroup = this.formBuilder.group({
      skill: ['', [Validators.required]],
      description: [''],
      level: [1],
      id: [null]
    });
    this.hardSkills.push(skillGroup);
    setTimeout(() => {
      const inputs = this.hardSkillsInputs.toArray();
      const lastInput = inputs[inputs.length - 1];
      if (lastInput) {
        lastInput.nativeElement.focus();
      }
    });
  }

  getJobSkillsWithId(id: string): void {
    this.jobsService.getJobSkills(id).subscribe({
      next: (result) => {
        this.jobSkills = result.body;
        const softSkills = result.body.filter((item: IJobSkill) => item.skillType === SkillType.SOFT);
        const hardSkills = result.body.filter((item: IJobSkill) => item.skillType === SkillType.HARD,);
        this.jobSkillForm.setControl('softSkills',this.formBuilder.array(softSkills.map((item: IJobSkill) =>
              this.formBuilder.group({
                skill: [item.skill, Validators.required],
                description: [item.description],
                level: [item.level],
                id: [item.id],
              })
            )
          ));
          this.jobSkillForm.setControl('hardSkills',this.formBuilder.array(hardSkills.map((item: IJobSkill) =>
            this.formBuilder.group({
              skill: [item.skill, Validators.required],
              description: [item.description],
              level: [item.level],
              id: [item.id],
            })
          )
        ));
      },
      error: (e) => { },
    });
  }

  get softSkills(): FormArray {
    return this.jobSkillForm.get('softSkills') as FormArray;
  }

  get hardSkills(): FormArray {
    return this.jobSkillForm.get('hardSkills') as FormArray;
  }

  addSoftSkill(): void {
    const skillGroup = this.formBuilder.group({
      skill: ['', [Validators.required]],
      description: [''],
      level: [1],
      id: [null]
    });
    this.softSkills.push(skillGroup);
    setTimeout(() => {
      const inputs = this.softSkillsInputs.toArray();
      const lastInput = inputs[inputs.length - 1];
      if (lastInput) {
        lastInput.nativeElement.focus();
      }
    });
  }

  private saveAllSkills(): void {
    if (this.autosaveInProgress) {
      return;
    }
    this.autosaveInProgress = true;
    const softSkills$ = this.saveSkills(this.softSkills.controls, SkillType.SOFT);
    const hardSkills$ = this.saveSkills(this.hardSkills.controls, SkillType.HARD);
  
    concat(softSkills$, hardSkills$).subscribe({
      next: () => {
        this.jobSkillForm.markAsPristine();
        this.markControlsPristine(this.softSkills.controls);
        this.markControlsPristine(this.hardSkills.controls);
        this.showSaved = true;
        this.showSaving = false;
        this.showSavedChanged.emit(this.showSaved);
        this.showSavingChanged.emit(this.showSaving);
        this.autosaveInProgress = false;
      },
      error: (error) => {
        console.error("Error occurred while saving skills:", error);
        this.showSaving = false;
        this.showSavingChanged.emit(this.showSaving);
        this.autosaveInProgress = false;
      }
    });
  }
  
  
  private markControlsPristine(skillControls: AbstractControl[]): void {
    skillControls.forEach(control => {
      control.markAsPristine(); 
    });
  }
  
  private saveSkills(skillControls: AbstractControl[], skillType: SkillType): Observable<any> {
    const skillsData = skillControls.map(control => {
      const isNew = !control.value.id;
      const isModified = control.dirty;
      return {
        skill: control.value.skill,
        description: control.value.description,
        level: control.value.level,
        jobId: this.jobId,
        skillType,
        id: control.value.id,
        isNew,
        isModified,
      };
    });
  
    return from(skillsData).pipe(
      concatMap(skill => {
        if (skill.isNew) {
          const { isNew, isModified, ...payload } = skill;
          return this.jobsService.createJobSkill(payload).pipe(
            tap((response) => {
              const control = skillControls.find(c => c.value.skill === skill.skill);
              if (control) {
                control.patchValue({ id: response.body.id }, { emitEvent: false });
                control.markAsPristine(); 
              }
            })
          );
        } else if (skill.isModified) {
          const { isNew, isModified, ...payload } = skill;
          return this.jobsService.updateJobSkill(payload).pipe(
            tap(() => {
              const control = skillControls.find(c => c.value.id === skill.id);
              if (control) {
                control.markAsPristine();
              }
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  removeSoftSkill(index: number): void {
    const skillId = this.softSkills.at(index).value.id;
    if (skillId) {
      this.deleteJobSkill(skillId, SkillType.SOFT, index);
    } else {
      this.softSkills.removeAt(index);
    }
  }

  removeHardSkill(index: number): void {
    const skillId = this.hardSkills.at(index).value.id;
    if (skillId) {
      this.deleteJobSkill(skillId, SkillType.HARD, index);
    } else {
      this.hardSkills.removeAt(index);
    }
  }

  deleteJobSkill(id: string, skillType: SkillType, index: number): void {
    this.jobsService.deleteJobSkill(id).subscribe({
      next: () => {
        if (skillType === SkillType.SOFT) {
          this.softSkills.removeAt(index);
        } else {
          this.hardSkills.removeAt(index);
        }
      },
      error: (e) => {
        this.toastService.showToast($localize`Error occurred while deleting, please try again.`, 'error');
      }
    });
  }

  getFullArray(level: number): boolean[] {
    return Array.from({ length: 10 }, (_, i) => i < level);
  }

  setHardSkillLevel(skillControl: AbstractControl, level: number): void {
    skillControl.patchValue({ level });
    skillControl.markAsDirty();
  }

  ngOnDestroy(): void {
    if (!this.savingInProgress && this.jobSkillForm.dirty) {
      this.resetAutosaveTimer(true); 
    }
    if (this.autosaveSubscription) {
      this.autosaveSubscription.unsubscribe();
    }
    if (this.autosaveTimer) {
      clearTimeout(this.autosaveTimer);
    }
  }

}
