<div *ngIf="field === 'status'; else skeleton">
  <span *ngIf="isItemProcessing(status)" class="badge status-processing"
    >Processing...</span
  >
  <span *ngIf="status === processStatus.STRUCTUREREADY" class="badge status-structure-ready"
    >Structure Ready</span
  >
  <span *ngIf="status === processStatus.ERROR" class="badge status-error"
  >Error</span
>
</div>
<ng-template #skeleton>
  <ngx-skeleton-loader
    *ngIf="field != 'status' && isItemProcessing(status); else columnsTemplate"
    count="1"
    animation="progress-dark"
    [theme]="{ 'margin-bottom': '0px', height: '8px' }"
  >
  </ngx-skeleton-loader>
</ng-template>
