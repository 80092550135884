<label for="search" class="sr-only">Search</label>
<div class="relative">
  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
    <i class="w-5 h-5 text-gray-400 icon-search flex items-center"></i>
  </div>
  <input
    #searchInput
    class="search-input"
    name="search"
    type="search"
    placeholder="Search"
    [(ngModel)]="searchTerm"
    (ngModelChange)="onSearch()"
    [maxlength]="lengthType.SHORT"
  />
</div>
