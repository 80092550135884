import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrl: './profile-settings.component.scss'
})
export class ProfileSettingsComponent  implements OnInit {
  jobDataForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
    this.jobDataForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: [''],
    });
  }

  ngOnInit(): void {
    this.authService.profileSettings().subscribe({
      next: (data: any) => {
        const profile = data.body;
        if (profile) {
          this.jobDataForm.setValue({
            fullName: profile.fullName || '',
            email: profile.email || ''
          });
          this.jobDataForm.get('email')?.disable();
        }
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      }
    });
  }

  submit(): void {
    if (this.jobDataForm.valid && this.jobDataForm.dirty) {
      const updatedData = this.jobDataForm.value;
      this.authService.updateProfileSettings(updatedData).subscribe({
        next: () => {
          this.jobDataForm.markAsPristine();
        },
        error: (error) => {
          console.error('Error updating profile:', error);
        }
      });
    }
  }
}
