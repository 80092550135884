import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrl: './badge.component.scss',
    standalone: false
})
export class BadgeComponent {

  @Input() label:  string = '';
  @Input() color:  string = '';
}
