import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[tableCustomCell]',
    standalone: false
})
export class TableCustomCellDirective {
  @Input('tableCustomCell') columnField!: string;

  constructor(public templateRef: TemplateRef<any>) {}
}
