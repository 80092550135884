import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IListCount } from 'src/app/components/table/models/list-count.interface';
import { TableComponent } from 'src/app/components/table/table.component';

@Component({
  selector: 'app-job-candidates',
  templateUrl: './job-candidates.component.html',
  styleUrl: './job-candidates.component.scss'
})
export class JobCandidatesComponent implements OnInit {

  public isLoading: boolean = true;
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  @Output() sendCandidates = new EventEmitter<string>();

  get isCompareDisabled(): boolean {
    return this.tableComponent?.selection?.selected.length !== 2;
  }
  selectedCandidateIds: any;

  public columns: IMatColumn[] = [
    {
      field: 'fullName',
      header: 'CANDIDATE NAME',
      sortable: true,
      searchKey: 'fullname',
    },
    { 
      field: 'email', 
      header: 'EMAIL', 
      sortable: true, 
      searchKey: 'email' 
    },
    {
      field: 'activeJobTitle',
      header: 'CURRENT ROLE',
      sortable: true,
      searchKey: 'activeJobTitle',
    },
    {
      field: 'location',
      header: 'LOCATION',
      sortable: true,
      searchKey: 'location',
    },
    {
      field: 'jobMatchScore',
      header: 'SCORE',
      sortable: true,
      searchKey: 'jobMatchScore',
    },
    // {
    //   field: 'activityStatus',
    //   header: 'STATUS',
    //   sortable: true,
    // },
    // {
    //   field: 'rating',
    //   header: 'RATING',
    //   sortable: true,
    // },
    { 
      field: 'createdAt', 
      header: 'DATE ADDED', 
      sortable: true 
    },
  ];


  public tableConfig: ITableConfig = {
    enableRowActions: false,
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-createdAt',
    } as ISievePayload,
  };

  candidateId!: string;
  jobId: string = '';
  @Output() public openSidenav = new EventEmitter<string | null>();
  candidateEndpoint: string = '';
  sidenav: any;
  
  constructor(
    private route: ActivatedRoute,
  ){ }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params['jobId'];
    this.candidateEndpoint = `job/${this.jobId}/candidate`;
  }

  public onListCountUpdated(): void {
    this.isLoading = false;
  }

  onItemSelect(id: string | null) {
    if (id) {
      this.candidateId = id;
      this.openSidenav.emit(id);
    }
  }

  compareCandidates() {
    this.selectedCandidateIds = this.tableComponent.selection.selected;
    this.sendCandidates.emit(this.selectedCandidateIds)
  }
}
