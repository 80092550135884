import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, firstValueFrom, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment.staging';
import { JobCandidateStatus } from '../../jobs/services/jobs.service';

@Injectable({
  providedIn: 'root',
})
export class CandidatesService {
  private readonly JOB_ENDPOINT: string = `${environment}/job`;
  private readonly JOB_SKILLS_ENDPOINT: string = `${environment.link}/job-skills`;
  private readonly ORG_CANDIDATES_ENDPOINT: string = `${environment.link}/organization/candidates`;

  constructor(private http: HttpClient) {}

  generateWithPrompt(data: JobPromptData) {
    return this.http
      .post<any>(`${this.JOB_ENDPOINT}/generate`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  generateWithFile(data: FormData) {
    return this.http
      .post<any>(`${this.JOB_ENDPOINT}/upload`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  applyJob(jobId: string, data: FormData) {
    return this.http
      .post<any>(`${this.JOB_ENDPOINT}/${jobId}/apply`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  getJobById(id: string) {
    return this.http
      .get<any>(`${this.JOB_ENDPOINT}/${id}`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(
        filter((res) => res.body.jobSummary !== null),
        take(1),
      );
  }

  updateCandidateAd(data: ICandidateData) {
    return this.http
      .put<any>(`${this.JOB_ENDPOINT}`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  public getCandidates(payload: IGetCandidatePayload) {
    return this.http
      .get<any>(`${this.ORG_CANDIDATES_ENDPOINT}`, {
        responseType: 'json',
        observe: 'response',
        params: {
          ...payload,
        },
      })
      .pipe(take(1));
  }

  getCandidateById(id: string) {
    return this.http
      .get<any>(`${this.ORG_CANDIDATES_ENDPOINT}/${id}/full-profile`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(
        filter((res) => res.body !== null),
        take(1),
      );
  }

  getJobCandidateById(id: string) {
    return this.http
      .get<any>(
        `${environment.link}/job-candidates/${id}`,
        {
          responseType: 'json',
          observe: 'response',
        },
      )
      .pipe(
        filter((res) => res.body !== null),
        take(1),
      );
  }

  getCandidateImage(id: any) {
    return this.http
      .get(`${this.ORG_CANDIDATES_ENDPOINT}/${id}/image`, {
        responseType: 'blob', 
        observe: 'response', 
      })
      .pipe(
        take(1)
      );
  }

  async updateCandidateStatus(request: IUpdateCandidateStatusRequest) {
    const responseObservable = this.http
      .put(`${environment.link}/job-candidates`, request)
      .pipe(take(1));

    const response = await firstValueFrom(responseObservable);
    return response;
  }

  public deleteOrgCandidate(id: string): Observable<any> {
    return this.http
      .delete<any>(`${this.ORG_CANDIDATES_ENDPOINT}/${id}`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  public batchDeleteOrgCandidates(ids: string[]): Observable<any> {
    const options = {
      body: { ids },
      responseType: 'json' as const,
      observe: 'response' as const,
    };
    return this.http
      .delete<any>(`${this.ORG_CANDIDATES_ENDPOINT}/batch-delete`, options)
      .pipe(take(1));
  }

  public getCandidateFile(id: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.ORG_CANDIDATES_ENDPOINT}/${id}/file`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}

export interface JobPromptData {
  includeCompanyDetails: boolean;
  content: string;
  generatorTone: number;
}

export interface JobfileData {
  includeCompanyDetails: boolean;
  file: File;
}

export interface ICandidateData {
  id: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  candidateId: any;
  organizationId: string;
  fullName: string;
  initials: string;
  email: string;
  location: string;
  role: string;
  activeJobTitle: string;
  externalStatus: CandidatesExternalStatus;
}

export enum CandidatesExternalStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  NEEDS_UPDATE = 'Needs update',
  ERROR = 'Error',
  ARCHIVED = 'Archived',
}

export interface IGetCandidatePayload {
  sorts?: string;
  filters?: string;
  page: number;
  pageSize: number;
}

export interface IUpdateCandidateStatusRequest {
  id: string;
  jobId: string;
  orgCandidateId: string;
  externalStatus: JobCandidateStatus;
}
