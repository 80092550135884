import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CandidatesExternalStatus } from '../../service/candidates.service';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IListCount } from 'src/app/components/table/models/list-count.interface';
import { LoadingProcessStatus, isItemProcessing } from 'src/app/shared/store/loading-process-status';

@Component({
  selector: 'app-candidates-list',
  templateUrl: './candidates-list.component.html',
  styleUrls: ['./candidates-list.component.scss'],
})
export class CandidatesListComponent implements OnInit {
  public isLoading: boolean = true;
  public hasInitialData: boolean = true;
  public statusRefreshTrigger: boolean = false;
  public processStatus = LoadingProcessStatus;
  public isItemProcessing = isItemProcessing;
  public columns: IMatColumn[] = [
    {
      field: 'fullName',
      header: 'CANDIDATE NAME',
      sortable: true,
      searchKey: 'fullname',
    },
    { field: 'email', header: 'EMAIL', sortable: true, searchKey: 'email' },
    {
      field: 'location',
      header: 'LOCATION',
      sortable: true,
      searchKey: 'location',
    },
    {
      field: 'activeJobTitle',
      header: 'CURRENT ROLE',
      sortable: true,
      searchKey: 'activejobtitle',
    },
    {
      field: 'status',
      header: 'STATUS',
      sortable: true,
    },
    { field: 'modifiedAt', header: 'LAST MODIFIED', sortable: true },
    { field: 'createdAt', header: 'DATE ADDED', sortable: true },
  ];

  public tableConfig: ITableConfig = {
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-modifiedAt',
    } as ISievePayload,
  };

  constructor(
    public dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  public redirectToPage(id: string): void {
    this._router.navigate(['hiring-portal/candidates/candidate', id]);
  }

  public get Status() {
    return CandidatesExternalStatus;
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }

  public onUploadModalOpen(isOpen: boolean): void {
    this.statusRefreshTrigger = isOpen;
  }
}
