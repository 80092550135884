import { Component, Input, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { CRUDService } from 'src/app/services/crud/crud.service';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastService } from 'src/app/shared/helper-services/toast.service';

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html',
    styleUrl: './delete-confirmation.component.scss',
    standalone: false
})
export class DeleteConfirmationComponent implements AfterViewInit {
  @Input() selection = new SelectionModel<string>(true, []);
  @Input() selectedId!: string | undefined;
  @Input() confirmationMessage!: string | undefined;
  @Input() openConfirmationModal!: boolean;
  @Input() isLoading!: boolean;
  @Input() endPoint!: string;

  @Output() selectedIdChange = new EventEmitter<string | undefined>();
  @Output() openConfirmationModalChange = new EventEmitter<boolean>();
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @Output() handleDelete = new EventEmitter<number>();

  @ViewChild(ConfirmationModalComponent)
  confirmationModal!: ConfirmationModalComponent;

  constructor(
    private _crudService: CRUDService,
    private toastService: ToastService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.onDelete();
    }, 0);
  }

  public onDelete(): void {
    this.confirmationModal.openModal();
  }

  public onModalConfirm(result: boolean) {
    if (!result) {
      this.selectedIdChange.emit(undefined);
      this.openConfirmationModalChange.emit(false);
      return;
    }

    const selectedIds = this.selection.selected;

    if (this.selectedId || selectedIds.length === 1) {
      this.isLoadingChange.emit(true);
      this._crudService.delete(this.endPoint, this.selectedId ?? selectedIds[0]).subscribe({
        next: () => this.handleDeleteResponse(1),
        error: (e) => {
          this.isLoadingChange.emit(false);
          this.openConfirmationModalChange.emit(false);
          this.toastService.showToast($localize`Unable to delete the selected item`, 'error');
          console.error('Delete failed', e);
        },
      });
    } else if (selectedIds.length > 1) {
      this.isLoadingChange.emit(true);
      this._crudService.batchDelete(this.endPoint, selectedIds).subscribe({
        next: () => this.handleDeleteResponse(selectedIds.length),
        error: (e) => {
          this.isLoadingChange.emit(false);
          this.openConfirmationModalChange.emit(false);
          this.toastService.showToast($localize`Unable to delete the selected items`, 'error');
          console.error('Batch delete failed', e);
        },
      });
    } else {
      this.openConfirmationModalChange.emit(false);
    }
  }

  private handleDeleteResponse(deletedCount: number): void {
    this.isLoadingChange.emit(false);
    this.selectedIdChange.emit(undefined);
    this.openConfirmationModalChange.emit(false);
    this.handleDelete.emit(deletedCount);
  }
}
