import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobsService } from '../../services/jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownItem } from 'src/app/core-ui/models';
import { environment } from 'src/environments/environment';
import { EmitType } from '@syncfusion/ej2-base';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/shared/helper-services/toast.service';

@Component({
    selector: 'app-create-job',
    templateUrl: './create-job.component.html',
    styleUrls: ['./create-job.component.scss'],
    standalone: false
})
export class CreateJobComponent implements AfterViewInit {
  createJobForm: FormGroup;
  isGenerating: boolean = false;
  isGenerateToasting: boolean = true;
  toast_type: string = '';
  toneLevels: string[] = [
    $localize`Startup`,
    $localize`Neutral`,
    $localize`Corporate`,
  ];
  languageOptions: IDropdownItem[] = [
    { value: 'En', label: $localize`English` },
  ];
  createOption: string;
  showDetails = true;
  disableUpload: boolean = true;
  @Output() isGeneratingEvent = new EventEmitter<boolean>();

  @ViewChild('templateupload')
  public uploadObj!: UploaderComponent;

  private _uploadButton: HTMLButtonElement | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private jobsService: JobsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private elementRef: ElementRef,
    private toastService: ToastService
  ) {
    this.createJobForm = this.formBuilder.group({
      content: ['', [Validators.required, Validators.nullValidator]],
      generatorTone: [1, [Validators.required, Validators.nullValidator]],
      language: ['English', [Validators.required, Validators.nullValidator]],
      includeCompanyDetails: [
        false,
        [Validators.required, Validators.nullValidator],
      ],
      fileSource: ['', [Validators.required]],
    });
    this.createOption = this.route.snapshot.params['option'];
  }

  ngAfterViewInit(): void {
    this._uploadButton =
      this.elementRef.nativeElement.querySelector('.e-css.e-btn');
  }

  onSubmit(): void {
    this.isGenerateToasting = true;

    this.disableUpload = true;
    this.uploadObj.upload();
  }

  generateWithFile(): void {
    const formData = new FormData();
    formData.append('file', this.createJobForm.get('fileSource')?.value);
    formData.append(
      'includeCompanyDetails',
      this.createJobForm.get('includeCompanyDetails')?.value,
    );

    this.isGenerating = true;
    this.isGeneratingEvent.emit(this.isGenerating);

    this.jobsService.generateWithFile(formData).subscribe({
      next: (result) => {
        this.getJobById(result.body.id);
      },
      error: (e) => {
        this.toastService.showToast($localize`Something went wrong, try again later`, 'error');
      },
      complete: () => {},
    });
  }


  getJobById(jobId: string): void {
    this.jobsService.getJobById(jobId).subscribe({
      next: (result) => {
        this.redirectToJobPage(result.body.id);
      },
      error: (e) => {},
      complete: () => {},
    });
  }

  redirectToJobPage(jobId: string): void {
    this.router.navigate(['hiring-portal/jobs/job', jobId]);
  }

  onFileChange(file: any) {
    this.createJobForm.patchValue({
      fileSource: file,
    });
    this.disableUpload = false;
  }

  onFileDelete(event: any) {
    this.createJobForm.patchValue({
      fileSource: '',
    });
    this.disableUpload = true;
  }

  toneChanged(event: any) {
    this.createJobForm.patchValue({ generatorTone: +event });
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  public onBack(): void {
    this.router.navigate(['hiring-portal/jobs/list']);
  }

  // TODO

  endMyToast(): void {
    this.isGenerateToasting = false;
  }

  // ejs

  public path: Object = {
    saveUrl: `${environment.link}/job/upload`,
  };

  public changeHandler: EmitType<Object> = () => {
    this.uploadObj.clearAll();
  };

  public changedHandler: EmitType<Object> = () => {
    this.uploadObj.clearAll();
  };

  public openUploadDialog(): void {
    this._uploadButton?.click();
  }

  public onUploading(args: any) {
    this.disableUpload = true;
    this.isGenerating = true;
    const token = this.authService.getToken();
    args.currentRequest.setRequestHeader('Authorization', `Bearer ${token}`);
  }

  public onCompleteUpload(): void {
    this.disableUpload = true;
    this.isGenerating = false;
  }

  onFileSelected(args: any): void {
    this.disableUpload = args.filesData.length === 0;
  }
}
