import { Component } from '@angular/core';

@Component({
    selector: 'app-base-layout',
    templateUrl: './base-layout.component.html',
    styleUrl: './base-layout.component.scss',
    standalone: false
})
export class BaseLayoutComponent {

}
