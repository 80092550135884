import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IJobCandidate } from '../../services/jobs.service';
import { CandidatesExternalStatus } from 'src/app/modules/hiring-portal/modules/candidates/service/candidates.service';

@Component({
    selector: 'app-job-list-item',
    templateUrl: './job-list-item.component.html',
    styleUrl: './job-list-item.component.scss',
    standalone: false
})
export class JobListItemComponent {
  @Input() public item: IJobCandidate | null = null;
  @Output() public selectItem = new EventEmitter<string | null>();

  listSkills = [
    {
      skill: 'C#, .NET (Core), Entity Framework',
      level: 8,
      description: null,
      skillType: 'Hard',
      cvId: '431f2cb6-5069-4c73-97cc-748718fb39fe',
      id: '5c9521fd-f601-4082-86ed-52cdc51f67ba',
      createdBy: '00000000-0000-0000-0000-000000000000',
      modifiedBy: '00000000-0000-0000-0000-000000000000',
      deletedBy: null,
      createdAt: '2024-05-30T12:06:35.69498+00:00',
      modifiedAt: '2024-05-30T12:06:35.694981+00:00',
      deletedAt: null,
    },
  ];

  constructor() {}

  public get fullArray(): boolean[] {
    let level = this.item?.profileCompleteness as number;
    if (level > 10) {
      level = Math.round(level / 10.0);
    }

    return Array.from({ length: 10 }, (_, i) => i < level);
  }

  public get initials(): string {
    const names = this.item?.fullName.split(' ');
    const initials = names?.map((name) => name.charAt(0)).join('');
    return initials?.toUpperCase() || '';
  }

  showDetails(): void {
    const id = this.item?.id ?? null;
    this.selectItem.emit(id);
  }

  openPdf(): void {
    window.open(this.item?.originalFileDownloadLink, '_blank');
  }

  public get Status() {
    return CandidatesExternalStatus;
  }

  getFullArray(level: number): boolean[] {
    return Array.from({ length: 10 }, (_, i) => i < level);
  }
}
