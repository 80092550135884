import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleService } from 'src/app/modules/auth/role/user-role.service';
import { AuthService } from 'src/app/services/auth.service';
import { LengthType } from 'src/app/shared/store/length-type';

@Component({
    selector: 'app-profile-settings',
    templateUrl: './profile-settings.component.html',
    styleUrl: './profile-settings.component.scss',
    standalone: false
})
export class ProfileSettingsComponent implements OnInit {
  jobDataForm: FormGroup;
  role: string | undefined;
  public lengthType = LengthType;
  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private roleService: RoleService
  ) {
    this.jobDataForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: [''],
      role: [''],
    });
  }

  ngOnInit(): void {
    this.roleService.getRole().then((role) => {
      this.role = role;

      this.authService.profileSettings().subscribe({
        next: (data: any) => {
          const profile = data.body;
          if (profile) {
            this.jobDataForm.setValue({
              fullName: profile.fullName || '',
              email: profile.email || '',
              role: this.role || '',
            });
            this.jobDataForm.get('email')?.disable();
            this.jobDataForm.get('role')?.disable();
          }
        },
        error: (error) => {
          console.error('Error fetching data:', error);
        },
      });
    });
  }

  submit(): void {
    if (this.jobDataForm.valid && this.jobDataForm.dirty) {
      const updatedData = this.jobDataForm.value;
      this.authService.updateProfileSettings(updatedData).subscribe({
        next: () => {
          this.jobDataForm.markAsPristine();
        },
        error: (error) => {
          console.error('Error updating profile:', error);
        },
      });
    }
  }
}
