import { Component, Input, OnInit } from '@angular/core';
import { Candidate } from '../../models/candidate.model';
import { CandidatesService } from '../../../service/candidates.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-candidate-meta-data',
  templateUrl: './candidate-meta-data.component.html',
  styleUrls: ['./candidate-meta-data.component.scss'],
})
export class CandidateMetaDataComponent implements OnInit {
  @Input() data: Candidate | null;
  imageSrc: string | null = null;

  constructor(
    public candidatesService: CandidatesService,
    public helperService: HelperService,
  ) {
    this.data = null;
  }

ngOnInit() {
  this.candidatesService.getCandidateImage(this.data?.id).subscribe({
    next: (result) => {
      const blob = result.body; 
      if (blob) { 
        const url = URL.createObjectURL(blob); 
        this.imageSrc = url; 
      }
    },
    error: (e) => {},
    complete: () => {},
  });
}

  public openLinkedInPage(): void {
    if (this.data && this.data.contact.linkedIn) {
      window.open(this.data.contact.linkedIn, '_blank');
    }
  }

  public openGitHubPage(): void {
    if (this.data && this.data.contact.github) {
      window.open(this.data.contact.github, '_blank');
    }
  }

  public get name(): string {
    return this.data?.fullName || '';
  }

  public get position(): string {
    return this.data?.activeJobTitle || '';
  }

  public get company(): string {
    if (!this.data?.jobs || this.data.jobs.length === 0) {
      return '';
    }

    const firstJob = this.data.jobs[0];
    return firstJob.company || '';
  }

  public get status(): string {
    return this.data?.status || '';
  }

  public get skils(): string[] {
    return this.data?.skills?.slice(0, 4).map((skill) => skill.skill) || [];
  }

  public get restSkillsNo(): number {
    if (!this.data?.skills) {
      return 0;
    }

    const rest = this.data.skills.length - 5;
    return rest > 0 ? rest : 0;
  }
}
