import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-skeleton-page',
    templateUrl: './skeleton-page.component.html',
    styleUrl: './skeleton-page.component.scss',
    standalone: false
})
export class SkeletonPageComponent implements OnInit {
  @Input() title: string = '';
  @Input() subTitle: string = '';

  ngOnInit(): void {}
}
