import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { BlogsComponent } from './blogs/blogs.component';
import { LandingComponent } from './landing/landing.component';
import { LoadingComponent } from './loading/loading.component';
import { MainBoxComponent } from './main-box/main-box.component';
import { OsavusBoxComponent } from './osavus-box/osavus-box.component';
import { OsavusModalComponent } from './osavus-modal/osavus-modal.component';
import { OsavusSkillComponent } from './osavus-skill/osavus-skill.component';
import { PrivacyPolicyComponent } from './policies/privacy-policy/privacy-policy.component';
import { RegenerateComponent } from './regenerate/regenerate.component';
import { SkillFormComponent } from './skill-form/skill-form.component';
import { SliderComponent } from './slider/slider.component';
import { TermsComponent } from './terms/terms.component';
import { WaitListComponent } from './wait-list/wait-list.component';
import { CoreUIModule } from '../core-ui/core-ui.module';
import {
  BlogCategoryComponent,
  CommunityComponent,
  FaqSectionComponent,
  FeaturesComponent,
  FooterHeroComponent,
  HeroComponent,
  OsavusBlogComponent,
  PricingComponent,
  TestimonialsComponent,
  WebsiteFooterComponent,
  WebsiteHeaderComponent,
} from './website/micro-components';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { BlogComponent } from './blog/blog.component';
import { DirectivesModule } from '../directives/directives.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CookiePolicyComponent } from './policies/cookie-policy/cookie-policy.component';
import { MaterialModule } from '../material.module';
import { ResizableDivComponent } from './resizable-div/resizable-div.component';
import { SkillLevelIndicatorComponent } from './skill-level-indicator';
import { TableComponent } from './table/table.component';
import { DeleteConfirmationComponent } from './table/delete-confirmation/delete-confirmation.component';
import { SearchComponent } from './table/search/search.component';
import { RowActionsComponent } from './table/row-actions/row-actions.component';
import { GlobalUploadModalComponent } from './global-upload-modal/global-upload-modal.component';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DownloadPdfComponent } from './download-pdf/download-pdf.component';
import { OpenPdfComponent } from './open-pdf/open-pdf.component';
import { TermsAndConditionsComponent } from './policies/terms-and-conditions/terms-and-conditions.component';
import { ProcessSkeletonComponent } from './process-skeleton/process-skeleton.component';
import { SkeletonPageComponent } from './skeleton-page/skeleton-page.component';

const COMPONENTS = [
  BaseLayoutComponent,
  BlogsComponent,
  BlogComponent,
  LandingComponent,
  LoadingComponent,
  MainBoxComponent,
  OsavusBoxComponent,
  OsavusModalComponent,
  OsavusSkillComponent,
  PrivacyPolicyComponent,
  TermsAndConditionsComponent,
  RegenerateComponent,
  SkillFormComponent,
  SliderComponent,
  TermsComponent,
  WaitListComponent,
  BlogCategoryComponent,
  CommunityComponent,
  FaqSectionComponent,
  FeaturesComponent,
  FooterHeroComponent,
  HeroComponent,
  OsavusBlogComponent,
  PricingComponent,
  TestimonialsComponent,
  WebsiteFooterComponent,
  WebsiteHeaderComponent,
  ConfirmationModalComponent,
  CookiePolicyComponent,
  ResizableDivComponent,
  SkillLevelIndicatorComponent,
  TableComponent,
  GlobalUploadModalComponent,
  RowActionsComponent,
  DeleteConfirmationComponent,
  SearchComponent,
  DownloadPdfComponent,
  OpenPdfComponent,
  ProcessSkeletonComponent,
  SkeletonPageComponent
];

@NgModule({
  imports: [
    CommonModule,
    CoreUIModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    DirectivesModule,
    NgxSkeletonLoaderModule,
    MaterialModule,
    UploaderModule
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class ComponentsModule {}
