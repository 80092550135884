import { Component } from '@angular/core';
import { FaqService } from 'src/app/services/faq.service';
import { IFaq } from 'src/app/shared/models/cms/Faq.model';

@Component({
    selector: 'app-faq-section',
    templateUrl: './faq-section.component.html',
    styleUrls: ['./faq-section.component.scss'],
    standalone: false
})
export class FaqSectionComponent {
  active_faq: number = 0;
  faqs: IFaq[] = [];
  constructor(protected faqService: FaqService) {}

  ngOnInit() {
    this.listFaq();
  }

  listFaq = () =>
    this.faqService
      .getFaqList()
      .subscribe(
        (result) =>
          (this.faqs =
            result.body?.data.map((x) => <IFaq>{ ...x.attributes, Id: x.id }) ??
            []),
      );
}
