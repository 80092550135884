<main-box>
  <app-loading *ngIf="isLoading"></app-loading>
  <div class="flex flex-col w-full h-full gap-5 p-5 bg-gray-100 min-h-[75vh]" [ngClass]="hasInitialData ? 'bg-gray-100' : 'bg-white'">
    <ng-container *ngIf="hasInitialData || statusRefreshTrigger; else emptyList">
      <div class="w-full h-full overflow-hidden">
        <app-table
          [columns]="columns"
          [endPoint]="candidateEndpoint"
          [tableConfig]="tableConfig"
          [statusRefreshTrigger]="statusRefreshTrigger"
          (listCountUpdated)="onListCountUpdated($event)"
        >
          <ng-template tableCustomCell="fullName" let-item>
            <item-initials-and-link
              [initials]="item.initials"
              [value]="item.fullName"
              (click)="onItemSelect(item.id)"
            />
          </ng-template>

          <ng-template tableCustomCell="email" let-item>
            <item-copy [value]="item.email" />
          </ng-template>

          <ng-template tableCustomCell="location" let-item>
            <span class="text-gray-500 font-semibold custom-width" >{{ item.location }}</span>
          </ng-template>

          <ng-template tableCustomCell="jobMatchScore" let-item>
            <span class="inline-block h-8 w-8 rounded-full bg-green-100 text-xs font-semibold text-center leading-8">{{ item.jobMatchScore }}</span>
          </ng-template>

          <ng-template tableCustomCell="createdAt" let-item>
            <!-- TODO: move datTime pipe in table componenet and in future try to make the pipe to handel different type of values -->
            <span class="text-gray-500 font-semibold">{{ item.createdAt | date: 'MMM d y ' }}</span>
          </ng-template>

          <ng-template #status let-element let-columnsTemplate="columnsTemplate">
            <app-process-skeleton [status]="element.status" [columnsTemplate]="columnsTemplate"></app-process-skeleton>
          </ng-template>

          <ng-template #rowActions let-data>
            <app-download-pdf
              [ngClass]="'mr-3 actions-icon-button'"
              [id]="data.orgCandidateId"
              [type]="'cv'"
              [title]="'Download original CV'"
            ></app-download-pdf>
          </ng-template>

          <ng-template #tableActions>
            <app-primary-button label="Compare" [disabled]="isCompareDisabled" (click)="compareCandidates()">Compare</app-primary-button>
            <app-dropdown-button
              chevronPosition="right"
              label="Add candidates"
              [items]="bulkActions"
              (onItemClick)="onBulkAction($event)"
              [customColor]="true"
            ></app-dropdown-button>
          </ng-template>
        </app-table>
      </div>
    </ng-container>
  </div>
</main-box>

<div *ngIf="openUploadModal">
  <app-candidates-upload-modal
    [openModal]="true"
    [uploadUrl]="candidateUploadUrl"
    (onCandidateUpload)="onUploadModalClose($event)"
  ></app-candidates-upload-modal>
</div>

<ng-template #emptyList>
  <div *ngIf="!isLoading" class="flex flex-col items-center justify-center w-full gap-3">
    <img src="assets/woman_collects_stars_in_the_sky.svg" class="h-2/4" alt="add_candidate_image" />
    <p class="empty-list-text">Apply your first candidate</p>

    <app-button
      type="empty-list-add-blue"
      i18n-label
      label="Upload New Candidate"
      (onClick)="onBulkAction(bulkActions[1])"
    ></app-button>

    <app-button
      type="empty-list-add-transparent"
      i18n-label
      label="Apply Existing Candidate"
      (onClick)="onBulkAction(bulkActions[0])"
    ></app-button>
  </div>
</ng-template>
