import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CandidateListItem } from './candidate-list/models';
import { CandidatesService } from '../../service/candidates.service';
import { Candidate, Skill } from '../models/candidate.model';
import { Observable, catchError, finalize, of, switchMap, tap, timer } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrl: './candidate.component.scss',
})
export class CandidateComponent implements OnInit,OnChanges {
  candidateId: string = '';
  candidateData: Candidate | null = null;
  selectedIndex: number = -1;
  @Input() public candidateFromJob?: string;
  @Input() showCloseButton: boolean = true;

  @Output() closeEvent = new EventEmitter<any>();

  @ViewChild('candidateContentMain', { static: false }) candidateContentMain: | ElementRef | undefined;
  @ViewChildren('descriptionRef') descriptionRefs!: QueryList<ElementRef>;
  
  heights: number[] = [];
  isLoading = true;
  private currentRequestId = 0;

  constructor(
    private candidatesService: CandidatesService,
    private _router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const routeCandidateId = params.get('candidateId') || '';
        if (routeCandidateId && routeCandidateId !== this.candidateId) {
        this.candidateId = routeCandidateId;
        this._loadCandidateOrganizationData(routeCandidateId);
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.descriptionRefs) {
      this.descriptionRefs.forEach((descriptionRef, index) => {
        const height = descriptionRef.nativeElement.offsetHeight;
        this.heights[index] = height;
      });
      this.cdRef.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['candidateFromJob'] && changes['candidateFromJob'].currentValue) {
      this.candidateId = changes['candidateFromJob'].currentValue;
      this._loadCandidateJobData(this.candidateId);
    }
  }

  public onBack(): void {
    this._router.navigateByUrl('/hiring-portal/candidates');
  }

  public onAddNewCandidate(): void {}

  public onCandidateSelect(candidate: CandidateListItem): void {
    if (candidate.id !== this.candidateId) {
      this.candidateId = candidate.id;
      this._loadCandidateOrganizationData(candidate.id);
    }
  }

  getFullArray(level: any): boolean[] {
    return Array.from({ length: 10 }, (_, i) => i < level);
  }

  choose(skill: Skill, index: any): void {
    skill.level = index + 1;
  }

  sidenavClose() {
    this.closeEvent.emit(true);
  }

  private _handleCandidateData(result: any) {
    try {
      this.candidateData = result.body || this.candidateData;
      if (this.candidateData) {
        this.candidateData.jobs = this.candidateData.jobs.map((job) =>
        this._calculateJobExperience(job)
      );
        if (this.candidateContentMain) {
          this.candidateContentMain.nativeElement.scrollTop = 0;
        }
      }
    } catch (error) {
      this.candidateData = null;  
    }
  }
  
  private _loadCandidateData(fetchCandidate: (id: string) => Observable<any>, id: string) {
    const requestId = ++this.currentRequestId;
      this.candidateData = null;
    this.isLoading = true;
  
    fetchCandidate(id).pipe(
      tap(() => this.isLoading = true),
      switchMap((result) => {
        if (requestId === this.currentRequestId) {
          this._handleCandidateData(result);
        }
        return of(null);
      }),
      catchError((error) => {
        console.error('Error loading candidate data:', error);
        return of(null); 
      }),
      finalize(() => {
        timer(200).subscribe(() => this.isLoading = false);
      })
    ).subscribe();
  }
  private _loadCandidateOrganizationData(id: string) {
    this._loadCandidateData(this.candidatesService.getCandidateById.bind(this.candidatesService), id);
  }

  private _loadCandidateJobData(id: string) {
    this._loadCandidateData(this.candidatesService.getJobCandidateById.bind(this.candidatesService), id);
  }

  private _calculateJobExperience(job: any): any {
    try {
      const end = job.endDate ? moment(job.endDate) : moment();
      const start = moment(job.startDate);

      let experience = '';
      const years = end.diff(start, 'years');
      const months = end.diff(start, 'months') % 12;

      if (years > 0) {
        experience = `${years} ${years > 1 ? 'Years' : 'Year'}`;
        if (months > 0) {
          experience += ` ${months} ${months > 1 ? 'Months' : 'Month'}`;
        }
      } else if (months > 0) {
        experience = `${months} ${months > 1 ? 'Months' : 'Month'}`;
      } else {
        experience = 'Present';
      }

      return {
        ...job,
        experience,
      };
    } catch (error) {
      console.error('Error calculating job experience:', error);
      return job; 
    }
  }

  public get projects(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.projects) {
      return [];
    }

    const { projects } = this.candidateData;
    const projectNames = Object.keys(projects);
    const returnValue: Array<{ title: string; content: string }> = [];

    projectNames.forEach((projectName) => {
      returnValue.push({ title: projectName, content: projects[projectName] });
    });

    return returnValue;
  }

  public get languages(): string[] {
    if (!this.candidateData || !this.candidateData.languages) {
      return [];
    }

    const { languages: languagesData } = this.candidateData;
    const languages = Object.keys(languagesData);
    const returnValue: Array<string> = [];
    languages.forEach((language) => {
      returnValue.push(`${language} - ${languagesData[language]}`);
    });

    return returnValue;
  }

  public get references(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.references) {
      return [];
    }

    const { references } = this.candidateData;
    const referencesNames = Object.keys(references);
    const returnValue: Array<{ title: string; content: string }> = [];

    referencesNames.forEach((projectName) => {
      returnValue.push({ title: projectName, content: references[projectName] });
    });

    return returnValue;
  }

  public get awardsAndHonors(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.awardsAndHonors) {
      return [];
    }

    const { awardsAndHonors } = this.candidateData;
    const awardsAndHonorsNames = Object.keys(awardsAndHonors);
    const returnValue: Array<{ title: string; content: string }> = [];

    awardsAndHonorsNames.forEach((awardsAndHonorsNames) => {
      returnValue.push({ title: awardsAndHonorsNames, content: awardsAndHonors[awardsAndHonorsNames] });
    });

    return returnValue;
  }

  public get affiliations(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.affiliations) {
      return [];
    }

    const { affiliations } = this.candidateData;
    const affiliationsNames = Object.keys(affiliations);
    const returnValue: Array<{ title: string; content: string }> = [];

    affiliationsNames.forEach((affiliationsNames) => {
      returnValue.push({ title: affiliationsNames, content: affiliations[affiliationsNames] });
    });

    return returnValue;
  }

  public get hobbies(): { title: string; content: string }[] {
    if (!this.candidateData || !this.candidateData.hobbies) {
      return [];
    }

    const { hobbies } = this.candidateData;
    const hobbiesNames = Object.keys(hobbies);
    const returnValue: Array<{ title: string; content: string }> = [];

    hobbiesNames.forEach((hobbiesNames) => {
      returnValue.push({ title: hobbiesNames, content: hobbies[hobbiesNames] });
    });

    return returnValue;
  }

  public onEmailCopy(tooltip: MatTooltip): void {
    tooltip.show();

    setTimeout(() => {
      tooltip.hide();
    }, 1500);
  }
}
