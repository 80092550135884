import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { getRoleLabel, Roles } from './roles.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private roles: string[] = [];

  endpoint: string = `${environment.link}/organization/recruiter`;

  constructor(
    private http: HttpClient,
  ) {}


  fetchRoles(): Observable<string[]> {
    return this.http
    .get<string[]>(`${this.endpoint}/user-roles`);
  }

  setRoles(roles: string[]): void {
    this.roles = roles;
  }

  async hasRole(role: string): Promise<boolean> {
    const roles = await firstValueFrom(this.fetchRoles());
    this.setRoles(roles);
    return roles.includes(role);
  }

  async getRole(): Promise<string> {
    const roles = await firstValueFrom(this.fetchRoles());
    this.setRoles(roles);

    const role = roles.includes(Roles.OrgAdmin)
      ? Roles.OrgAdmin
      : roles.includes(Roles.Recruiter)
        ? Roles.Recruiter
        : Roles.Candidate;
    return getRoleLabel(role);
  }

  addRole(roleUpdateData: RoleUpdateData): Observable<any> {
    return this.http.post(`${this.endpoint}/add-role`, roleUpdateData);
  }
  
  removeRole(roleUpdateData: RoleUpdateData): Observable<any> {
    return this.http.post(`${this.endpoint}/remove-role`, roleUpdateData);
  }
}

export interface RoleUpdateData {
  email: string;
  role: string;
}

