import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-google-sso',
    templateUrl: './google-sso.component.html',
    styleUrls: ['./google-sso.component.scss'],
    standalone: false
})
export class GoogleSsoComponent {
  isToasing: boolean = false;
  toast_type: string = '';
  toast_message: string = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private routerActivate: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.routerActivate.snapshot.queryParams['code']) {
      this.ssoGoogleAuth();
    } else {
      this.toast_message = $localize`Something went wrong, try again later`;
      (this.toast_type = 'error'), (this.isToasing = true);
    }
  }

  ssoGoogleAuth(): void {
    let data = {
      code: this.routerActivate.snapshot.queryParams['code'],
      redirectUri: environment.googleSsoRedirectUri,
    };
    this.auth.signupWithGoogleSSO(data).subscribe({
      next: (result) => {
        this.toast_type = 'success';
        this.toast_message = $localize`Successfully logged in.`;
        this.isToasing = true;
        this.auth.setToken(result.body.jwtToken);
        this.router.navigate(['/hiring-portal']);
      },
      error: (e) => {
        this.toast_message = $localize`Something went wrong, try again later`;
        (this.toast_type = 'error'), (this.isToasing = true);
        this.router.navigate(['/login']);
        alert(e.error.message);
      },
      complete: () => console.info('complete'),
    });
  }

  endMyToast(): void {
    this.isToasing = false;
    this.toast_type === 'error'
      ? this.router.navigate(['/login'])
      : this.router.navigate(['/hiring-portal']);
  }
}
