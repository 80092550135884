<div class="relative">
  <button
    id="dropdownDefaultButton"
    data-dropdown-toggle="dropdown"
    data-dropdown-trigger="click"
    type="button"
    [ngClass]="{
      'text-gray-900': textColor,
      'text-gray-400': !textColor,
      flex: true,
      'align-middle': true,
      'gap-1': true,
      'items-center': true,
      'cursor-pointer': true,
      'border-2': !circleButton && !removeBorder,
      'px-3': !circleButton,
      'py-3': !circleButton,
      'rounded-lg': !circleButton,
      'cursor-not-allowed': disabled,
      'bg-gray-200': disabled,
      'text-gray-500': disabled,
      'rounded-full': circleButton,
      'px-1': circleButton,
      'py-1': circleButton,
      'bg-gray-100': circleButton,
      'leading-3': true,
      'border-osavus-blue-50 bg-osavus-blue-50 text-white': customColor
    }"
    (click)="openMenu()"
  >
    <ng-container *ngTemplateOutlet="chevronLeft"></ng-container>
    <i
      *ngIf="!leftPosition && icon && !circleButton"
      [ngClass]="{ 'leading-3': true }"
      aria-hidden="true"
      [class]="icon"
    ></i>
    <span class="font-semibold text-sm leading-3" *ngIf="!circleButton">
      {{ label }}
    </span>
    <ng-container *ngTemplateOutlet="chevronRight"></ng-container>
    <ng-container *ngTemplateOutlet="moreButton"></ng-container>
  </button>

  <!-- Dropdown menu -->
  <div
    id="dropdown"
    class="z-10 bg-white right-0 rounded-lg shadow w-44 absolute mt-1 overflow-hidden"
    [ngClass]="{ block: opened, hidden: !opened }"
  >
    <ul aria-labelledby="dropdownDefaultButton" class="text-left">
      <li *ngFor="let item of items">
        <div class="flex hover:bg-gray-100 cursor-pointer">
          <img *ngIf="item.icon" src="{{ item.icon }}" alt="icon svg" />
          <span role="presentation" class="block px-4 py-2 text-sm text-gray-700 font-normal" (click)="onClick(item)">
            {{ item.label }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</div>

<ng-template #chevronLeft>
  <i
    *ngIf="leftPosition && !circleButton"
    class="icon-chevron transform rotate-90 font-semibold text-lg leading-3"
    aria-hidden="true"
  ></i>
</ng-template>
<ng-template #chevronRight>
  <i
    *ngIf="!leftPosition && !circleButton"
    class="icon-chevron transform rotate-90 font-semibold text-lg leading-3"
    aria-hidden="true"
  ></i>
</ng-template>
<ng-template #moreButton>
  <img
    *ngIf="circleButton"
    src="assets/horizontal-dots.svg"
    alt="..."
    class="icon-dots font-semibold text-lg leading-3"
  />
</ng-template>
