import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
    standalone: false
})
export class InputComponent {
  @Input() placeholder: string = '';
  @Input() isIcon: string = '';
  @Input() label: string = '';
  @Input() autocomplete: string = '';
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() formControlName: string = '';
  @Input() isCustomSize: boolean = false;
  @Input() type?: string = 'text';
  @Input() maxLength: number = 255;

    constructor(public controlContainer: FormGroupDirective) {}

    get formControl() {
      return this.controlContainer.form.get(this.formControlName);
    }
  
    get hasError() {
      return this.formControl && this.formControl.invalid && this.formControl.touched;
    }
  
    get errorMessage() {
      if (this.formControl?.errors?.['required']) {
        return 'Field is required';
      }
      return 'Invalid input';
    }
}
