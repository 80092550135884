import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-regenerate',
  templateUrl: './regenerate.component.html',
  styleUrls: ['./regenerate.component.scss'],
})
export class RegenerateComponent {
  @Input() isLoading: boolean = false;
  @Input() isIcon: boolean = false;
  @Input() isOptions: boolean = false;
  @Input() section: string = '';
  @Output('regenerate') submitted = new EventEmitter();
  @Output('onClear') cleared = new EventEmitter();

  showOptions: boolean = false;
  selectedOption: number = 1;
  options: any[] = [];
  prompt: string = '';

  constructor() {
    this.options = [
      {
        title: $localize`Rewrite`,
        icon: 'rewrite.svg',
        value: 1,
      },
      {
        title: $localize`Make shorter`,
        icon: 'regenerate-text.svg',
        value: 2,
      },
      {
        title: $localize`Make longer`,
        icon: 'regenerate-text.svg',
        value: 3,
      },
      {
        title: $localize`Make number of lines`,
        icon: 'regenerate-text.svg',
        value: 4,
      },
      {
        title: $localize`Improve writing`,
        icon: 'light-bulb.svg',
        value: 5,
      },
    ];
  }

  regenerateSection() {
    if (this.prompt) {
      this.showOptions = false;
      let config = {
        section: this.section,
        prompt:
          this.prompt +
          (this.section == 'Title' ? ' but less than 200 character limit' : ''),
      };
      this.submitted.emit(config);
      this.prompt = "";
    }
  }

  onClear() {
    this.cleared.emit(this.section);
    this.prompt = "";
  }
}
