import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { LoadingProcessStatus, isItemProcessing } from 'src/app/shared/store/loading-process-status';

@Component({
  selector: 'app-process-skeleton',
  templateUrl: './process-skeleton.component.html',
  styleUrl: './process-skeleton.component.scss'
})
export class ProcessSkeletonComponent implements OnInit {

  @Input() field! : string;
  @Input() status! : string;
  @Input() columnsTemplate! : TemplateRef<any>;

  public processStatus = LoadingProcessStatus;
  public isItemProcessing = isItemProcessing;

  ngOnInit() {}

}
