import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OverviewComponent } from './components/overview/overview.component';
import { authGuard } from 'src/app/guards/auth.guard';
import { SettingsComponent } from './components/settings/settings.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'jobs',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/jobs/jobs-routing.module').then(
            (m) => m.JobsRoutingModule,
          ),
      },
      {
        path: 'candidates',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/candidates/candidates-routing.module').then(
            (m) => m.CandidatesRoutingModule,
          ),
      },
      { path: 'overview', component: OverviewComponent },
      { path: 'settings', component: SettingsComponent },
      { path: '', redirectTo: '/hiring-portal/overview', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HiringPortalRoutingModule {}
