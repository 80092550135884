import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { JobsService } from 'src/app/modules/hiring-portal/modules/jobs/services/jobs.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-global-upload-modal',
    templateUrl: './global-upload-modal.component.html',
    styleUrl: './global-upload-modal.component.scss',
    standalone: false
})
export class GlobalUploadModalComponent {
  @ViewChild('templateupload') public uploadObj!: UploaderComponent;
  private _uploadButton: HTMLButtonElement | undefined;

  public path: Object;

  constructor(
    private dialogRef: MatDialogRef<GlobalUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private elementRef: ElementRef
  ) {
    this.path = { saveUrl: this.data.uploadUrl };
  }

  ngAfterViewInit(): void {
    this._uploadButton = this.elementRef.nativeElement.querySelector('.e-css.e-btn');
  }

  public openUploadDialog(): void {
    this._uploadButton?.click();
  }

  public onUploading(args: any): void {
    const token = this.authService.getToken();
    args.currentRequest.setRequestHeader('Authorization', `Bearer ${token}`);
  }

  public onActionComplete(args: any): void {
    if (args.operation === 'upload') {
      this.closeModal();
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  onFileSelected(args: any): void {
    this.uploadObj.upload(); 
  }
}