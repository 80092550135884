import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CandidateListItem } from '../models';

@Component({
    selector: 'app-candidate-list-item',
    templateUrl: './candidate-list-item.component.html',
    styleUrls: ['./candidate-list-item.component.scss'],
    standalone: false
})
export class CandidateListItemComponent implements OnInit {
  @Input() isSelected: boolean;
  @Input() item: CandidateListItem | null;
  @Output() select: EventEmitter<CandidateListItem> = new EventEmitter();
  @ViewChild('candidateItem', { static: false }) candidateItem!: ElementRef;

  constructor() {
    this.isSelected = false;
    this.item = null;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.isSelected && this.candidateItem) {
      this.scrollIntoView();
    }
  }

  public onCandidateClick(): void {
    this.select.emit(this.item as CandidateListItem);
  }

  scrollIntoView() {
    if (this.candidateItem) {
      this.candidateItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public get label(): string {
    if (this.item) {
      return this.item.fullName;
    }
    return '';
  }

  public get position(): string {
    return this.item?.position || '';
  }
}
