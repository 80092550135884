import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-community',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss'],
    standalone: false
})
export class CommunityComponent {
  @Input() onlyForm: boolean = false;

  isLoading: boolean = false;
  responseMessage: string = '';

  communityForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
  ) {
    this.communityForm = new FormGroup({});

    this.communityForm.addControl(
      'email',
      this.formBuilder.control('', [
        Validators.required,
        Validators.nullValidator,
        Validators.email,
      ]),
    );
  }

  onSubmit() {
    const params = new HttpParams()
      .set('EMAIL', this.communityForm.value.email)
      .set('tags', '2971763');
    const mailChimpUrl = environment.mailChimp + params.toString();

    this.isLoading = true;

    this.auth.mailChimpSubmit(mailChimpUrl).subscribe({
      next: (result) => {
        this.responseMessage = result.msg.replace(/[0-9]/g, '');
        this.isLoading = false;
      },
      error: (error) => {
        this.responseMessage = $localize`Something went wrong, try again later`;
        this.isLoading = false;
      },
      complete: () => {},
    });
  }
}
