import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'hiring-header',
    templateUrl: './hiring-header.component.html',
    styleUrls: ['./hiring-header.component.scss'],
    standalone: false
})
export class HiringHeaderComponent implements OnInit{

  IsMenuActive: boolean = false;
  IsJobsActive: boolean = false;
  IsOverviewActive: boolean = true;
  IsReportsActive: boolean = false;
  IsCandidatesActive: boolean = false;
  userInitials!: string;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.userData().subscribe({
      next: (data: any) => {
        this.userInitials = data.body.initials;
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      }
    });
  }

  toggleMenu = () => {
    this.IsMenuActive = !this.IsMenuActive;
  };

  signout = () => {
    localStorage.removeItem('token');
    this.router.navigate(['login-portal/login']);
  };
}
