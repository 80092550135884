<div class="profile-settings-container">
    <form class="form" [formGroup]="jobDataForm" (ngSubmit)="submit()">
      <div>
        <app-input id="name" name="name" label="Your name" formControlName="fullName" placeholder="Your name" ngDefaultControl></app-input>
      </div>
      <div>
        <app-input id="email" name="email" label="Email" formControlName="email" placeholder="existing@email.com" ngDefaultControl></app-input>
      </div>
      <!-- <div class="flex items-center justify-between">
        <div>
          <label for="email-marketing" class="label">Email marketing</label>
          <p class="description-text">
            You will still receive critical notifications related to your account
          </p>
        </div>
        <div>
          <input type="checkbox" id="email-marketing" class="toggle-checkbox hidden" />
          <label for="email-marketing" class="toggle-label">
            <span class="dot"></span>
          </label>
        </div>
      </div>
      <div>
        <app-input id="password" type="password" name="password" label="Change password" formControlName="password"
          placeholder="••••••••" ngDefaultControl>
        </app-input>
      </div> -->
  
      <div class="button-group">
        <!-- <button type="button" class="delete-button" disabled>
          Delete account
        </button> -->
        <app-primary-button class="ml-auto" label="Update Profile" [disabled]="jobDataForm.pristine">Update Profile</app-primary-button>
      </div>
    </form>
</div>